;(function() {
/* @ngInject */
function ZalozenieSroKrok1Controller(ZalozenieSroKrok1Service, loading, _, $window, Helpers, ModalFinishLater, $filter,
                                     $scope, ModalStreetNumber, ModalTatraBanka, $timeout) {
  let vm = this;
  let server = ZalozenieSroKrok1Service;

  vm.maxStep = 1;
  vm.valid = false;
  vm.cart = { };
  vm.onCompanyNameChange = onCompanyNameChange;
  vm.onCompanySuffixChange = onCompanySuffixChange;
  vm.onCheckClick = onCheckClick;
  vm.onFirmarenAddressClick = onFirmarenAddressClick;
  vm.setFirmarenPlaceOfBusiness = setFirmarenPlaceOfBusiness;
  vm.onContinueClick = onContinueClick;
  vm.onSaveForLaterClick = onSaveForLaterClick;
  vm.onElementFocus = Helpers.onElementFocus;
  vm.scrollTo = Helpers.scrollTo;
  vm.onCityChange = onCityChange;
  vm.onCodeChange = onCodeChange;
  vm.onBlurEmail = onBlurEmail;
  vm.showTatraBankaModal = showTatraBankaModal;
  vm.onFirmarenPlaceChange = onFirmarenPlaceChange;

  vm.companyName = { name: "", suffix: "" };
  vm.companyNameState = {};
  vm.cities = [];
  vm.placeOfBusinessKinds = ["area", "flat", "otherBuilding", "house", "garage"]; // fixme: constant maybe?
  vm.firmarenPlacesOfBusiness = [];
  vm.isCollapsed = { firmarenAddress: true };
  vm.placeOfBusiness = {
    type: "own",
    voucher: ""
  };
  vm.nextStep = 2;
  vm.showStreetNumberModals = true;
  vm.isSinglePerson = false;

  vm.domains = {
      sk: {
        name: "",
        isFree: false,
        active: false
      },
     com: {
        name: "",
        isFree: false,
        active: false
      }
  };

  vm.isDuplicate = false;

  onCodeChange();

  window.showSpinner(10, "new_company_run_placeholder");
  server.getMaxStep().then(ms => {vm.maxStep = ms; window.hideSpinnerWhenAllResolved()});
  server.getCart().then(onCartLoad);
  server.getCompanyName().then(onCompanyNameLoad);
  server.getPlaceOfBusiness().then(onPlaceOfBusinessLoad);
  server.getCities().then(onCitiesLoad);
  server.getFirmarenPlacesOfBusiness().then(onFirmarenPlacesOfBusinessLoad);
  server.getVoucher().then(onVoucherLoad);
  server.getLandingSinglePerson().then(onSinglePersonLoad);
  server.getDomains().then(getDomains);
  server.getDuplicate().then(res => vm.isDuplicate = res);

  $scope.$on('new_company_run_placeholder', function(event, args) {
    var content = $filter("translate")("sro.zalozenie.krok1.placeholder");
    vm.icoPlaceholder = "";

    var i = 0;

    var timer = setInterval(function () {
      if (i < content.length)
        vm.icoPlaceholder += content[i];
      else clearInterval(timer);
      i++;
      $scope.$apply();
    }, 100);
  });

  vm.suffixes = [" s. r. o.", ", s. r. o.", ", spol. s r. o."];
  vm.companyName.suffix = vm.suffixes[0];
  vm.placeOfBusiness.kind =  $filter("translate")('placeOfBusinessKind.' + vm.placeOfBusinessKinds[0]);
  vm.requiredMessage = false;

  function getDomains(domains) {
    if(domains)
      vm.domains = domains;
    window.hideSpinnerWhenAllResolved();
  }

  function onSinglePersonLoad(isSingle) {
    vm.isSinglePerson = isSingle;
    window.hideSpinnerWhenAllResolved();
  }

  function showTatraBankaModal() {
    ModalTatraBanka('modalTatraBanka.sro.title', 'modalTatraBanka.sro.text', 86)
    .add()
    .result
    .then(res => {
      server.setTBDiscount((res && res === "confirm").toString()).then(onCartLoad);
    });
  }

  function onBlurEmail() {
    $scope.step1.email.focus=false;
  }

  function onVoucherLoad(voucher) {
    if(voucher && voucher.voucher) {
      vm.placeOfBusiness.voucher = voucher.voucher;
    }

  window.hideSpinnerWhenAllResolved();
  }

  function onCityChange($item) {
    vm.placeOfBusiness.address.zipCode = $item && $item._3;
  }

  function onCartLoad(c) {
    vm.cart.price = c.totalPrice;
    vm.cart.items = c.items;
    vm.cart.placeOfBusinessPrice = c.placeOfBusinessPrice;
    vm.cart.size = c.subjectCount;

    window.hideSpinnerWhenAllResolved();
  }

  function onCompanyNameLoad(cn) {
    if (angular.isDefined(cn)) {
      vm.companyName = cn;
      _saveCompanyName();
    }

      window.hideSpinnerWhenAllResolved();
  }

  function onCodeChange() {
    if(vm.placeOfBusiness.voucher) {
      server.setDiscount(vm.placeOfBusiness).then(({ success, error }) => {
        if(success) {
          // TODO docasne FIRM-2342
          vm.voucherError = "temp";
        } else {
          vm.voucherError = error;
        }
      });
    }
    onPriceChange();
  }

  function onPriceChange() {
    server.getCart().then(onCartLoad);
  }

  function onPlaceOfBusinessLoad(pb) {
    if (angular.isDefined(pb)) {
      if (pb.type === "own") {
        vm.placeOfBusiness = pb;
      }
      else if (pb.type === "firmaren") {
        vm.placeOfBusiness = { type: pb.type };
        vm.firmarenAddress = pb;
        vm.placeOfBusiness.email = pb.email;
      }
      server.getVoucher().then(onVoucherLoad);
    } else {
      server.getStringContent().then((data) => {
        if(data) {
          let placeOfBusiness = JSON.parse(data);
          if (placeOfBusiness.type === "own") {
            vm.placeOfBusiness = placeOfBusiness;
          }
          else if (placeOfBusiness.type === "firmaren") {
            vm.placeOfBusiness = { type: placeOfBusiness.type };
            vm.firmarenAddress = placeOfBusiness;
            vm.placeOfBusiness.email = placeOfBusiness.email;
          }
          server.getVoucher().then(onVoucherLoad);
        }
      });
    }

    window.hideSpinnerWhenAllResolved();
  }

  function onCitiesLoad(cs) {
    vm.cities = cs;

      window.hideSpinnerWhenAllResolved();

  }

  function onFirmarenPlacesOfBusinessLoad(ps) {
    vm.firmarenPlacesOfBusiness = ps;
    window.hideSpinnerWhenAllResolved();
  }

  function onCompanyNameChange() {
    if(vm.companyNameState.success) {
      vm.companyNameState = {};
    }
    saveCompanyName();
  }

  function onCompanySuffixChange() {
    saveCompanyName();
  }

  function onCheckClick() {
    _saveCompanyName();
  }

  let saveCompanyName = _.debounce(_saveCompanyName, 1000);
  function _saveCompanyName() {
    let name = vm.companyName.name;

    let result = server.setCompanyName(vm.companyName);
      if (name && name !== "") {
        if(name.length > 1) {
          vm.companyNameState = {loading: true};

          loading(result, 1000)
            .then(({data, error}) => {
              if (data) {
                vm.companyNameState = {success: true};
                vm.domains.sk.name = vm.companyName.name + ".sk";
                vm.domains.com.name = vm.companyName.name + ".com";
              }
              else {
                if(error === "notAvailable")
                  vm.companyNameState = {notAvailable: true};
                else vm.companyNameState = {error};
              }
            })
            .catch((e) => {
              console.log(`todo: ${e}`);
              vm.companyNameState = {error};
          });
        } else vm.companyNameState = {warn: true};
      } else {
        vm.companyNameState = {};
      }

  }

  function onFirmarenAddressClick() {
    vm.isCollapsed.firmarenAddress = !vm.isCollapsed.firmarenAddress;
  }

  function setFirmarenPlaceOfBusiness(place) {
    vm.firmarenAddress = place;
    vm.isCollapsed.firmarenAddress = true;
  }

  function checkStreetNumber() {
    let addresses = [vm.placeOfBusiness && vm.placeOfBusiness.address];

    return Helpers.fixAndValidateStreetNumbers(addresses);
  }

  function onFirmarenPlaceChange() {
    if(vm.placeOfBusiness.type == "firmaren" && (!vm.firmarenAddress || !vm.firmarenAddress.id)) {
      vm.firmarenAddress = vm.firmarenPlacesOfBusiness[0];
    }
    if(vm.placeOfBusiness.type == "firmaren") {
      vm.cart.placeOfBusinessPrice = vm.firmarenAddress.price;
    } else {
      vm.cart.placeOfBusinessPrice = null;
    }
  }

  function onContinueClick(steps) {
    let isMissingStreetNoPrefix = checkStreetNumber();

    $timeout(() => {
      if($scope.step1.$invalid) {
        vm.requiredMessage = true;
        //scrollTo("content");
        vm.submitted=true;
      } else {
        if (vm.showStreetNumberModals && isMissingStreetNoPrefix) {
          ModalStreetNumber()
              .add()
              .result
              .then();

          vm.showStreetNumberModals = false;
        } else {
          if (!vm.companyNameState.error) {
            let placeOfBusiness = vm.placeOfBusiness.type === "firmaren" ? vm.firmarenAddress : vm.placeOfBusiness;
            // todo: validate?, catch (takmer kazdy then by mal mat vo finalnej verzii catch)
            placeOfBusiness.email = vm.placeOfBusiness.email;

            server.setDomains(vm.domains).finally(() => {
              server.setPlaceOfBusiness(placeOfBusiness)
                  .then(() => {
                    if(steps){
                      vm.valid = true;
                    } else {
                      $window.location = "krok2";
                    }
                  })
                  .catch((e) => console.log(`todo: ${e}`));
            });
          } else {
            vm.scrollTo("content");
          }
        }
      }
    });
  }

  function onSaveForLaterClick() {
    let placeOfBusiness = vm.placeOfBusiness.type === "firmaren" ? vm.firmarenAddress : vm.placeOfBusiness;

    ModalFinishLater(server, JSON.stringify(placeOfBusiness))
      .add(server, JSON.stringify(placeOfBusiness))
      .result
      .then();
  }
}

angular.module("firmaren")
  .controller("ZalozenieSroKrok1Controller", ZalozenieSroKrok1Controller);
}());
