;(function() {
/* @ngInject */
function RoundUpDonationController(Helpers, $filter, $scope) {
    let vm = this;

    vm.items = [];

    vm.onElementFocus = Helpers.onElementFocus;

    $scope.$watch('vm.price', function () {
        getItems();
    }, true);

    function getItems() {
        vm.items = [];
        let roundedPrice = Math.ceil(vm.price);
        let firstRoundedPrice = roundedPrice;

        let roundUpAmount = parseFloat((roundedPrice - vm.price).toFixed(2));
        let firstRoundUpAmount = roundUpAmount;

        if(roundUpAmount === 0) {
            firstRoundUpAmount = 0.5;
            firstRoundedPrice = roundedPrice + 0.5;
        }

        vm.items.push({value: 0, name: $filter("translate")("roundUpDonation.without")});
        vm.items.push({value: firstRoundUpAmount, name: $filter("translate")("roundUpDonation.with", {fullPrice: firstRoundedPrice, donation: (firstRoundUpAmount).toFixed(2)})});
        vm.items.push({value: roundUpAmount + 1, name: $filter("translate")("roundUpDonation.with", {fullPrice: +roundedPrice + 1, donation: (+roundUpAmount + 1).toFixed(2)})});
        vm.items.push({value: roundUpAmount + 2, name: $filter("translate")("roundUpDonation.with", {fullPrice: +roundedPrice + 2, donation: (+roundUpAmount + 2).toFixed(2)})});
        vm.items.push({value: roundUpAmount + 5, name: $filter("translate")("roundUpDonation.with", {fullPrice: +roundedPrice + 5, donation: (+roundUpAmount + 5).toFixed(2)})});

        if(angular.equals(vm.model, {})) {
            vm.model = {
                type: vm.items[0].value,
                name: vm.items[0].name
            }
        } else if(vm.model.name === "") {
            vm.items.forEach(i => {
                if(i.value === vm.model.type) {
                    vm.model.name = i.name;
                }
            });
        }
    }
}

angular.module("firmaren")
    .component("roundUpDonation", {
        templateUrl: "/app/shared/round-up-donation/round-up-donation.html",
        controller: RoundUpDonationController,
        controllerAs: "vm",
        bindings: {
            price: "=",
            model: "="
        }
    });
}());
