;(function() {
/* @ngInject */
function VirtualneSidloKrok2Controller(VirtualneSidloKrok2Service, UserService, $window, ModalForgotPassword, $filter,
                                       $scope, $timeout, ModalStreetNumber, $rootScope, ModalFinishLater, Helpers) {
    let vm = this;
    let service = VirtualneSidloKrok2Service;
    let server = UserService;

    vm.onSaveAndContinueClick = onSaveAndContinueClick;
    vm.onSaveForLaterClick = onSaveForLaterClick;
    vm.onBackClick = onBackClick;
    vm.onElementFocus = onElementFocus;
    vm.onEmailChange = onEmailChange;
    vm.onPasswordChange = onPasswordChange;
    vm.showModalForgotPassword = showModalForgotPassword;
    vm.fullName = fullName;
    vm.onIcoChange = onIcoChange;

    vm.maxStep = 2;
    vm.nextStep = 3;
    vm.valid = false;
    vm.showStreetNumberModals = true;
    vm.cart = {
        text: "/rok"
    };
    vm.order = {
        paymentInfo: {
            address: {},
        },
        contactInfo: {
            address: {}
        },
        additionalInfo: {}
    };

    userLoad();

    $scope.$on('user-changed', function(event, args) {
        userLoad();
    });

    $scope.$on('on-password-change', function(event, data) {
        if(vm.order.contactInfo)
            vm.order.contactInfo.password = data;

        onPasswordChange();
    });

    $scope.$on('on-email-change', function(event, args) {
        onEmailChange();
    });

    window.showSpinner(4);
    service.getMaxStep().then( (ms) => { vm.maxStep = ms; window.hideSpinnerWhenAllResolved(); });
    service.getPrice().then(onPriceLoad);
    service.getCities().then(onCitiesLoad);
    service.getCountries().then(onCountriesLoad);
    service.getOrder().then(onOrderLoad);

    function onIcoChange(id, ico) {
        service.getFinstatInfo({name: ico}).then( data => {
            if(data) {
                vm.order.paymentInfo.companyName = data.name;
                vm.order.paymentInfo.dic = data.dic;
                vm.order.paymentInfo.icdph = data.icdph;
                vm.order.paymentInfo.address = data.address;

                vm.order.paymentInfo.address.country = vm.countries[0]._2;

                if(vm.order.contactInfo && !vm.order.contactInfo.ico) {
                    vm.order.contactInfo.ico = ico;
                    vm.order.contactInfo.companyName = data.name;
                    vm.order.contactInfo.dic = data.dic;
                    vm.order.contactInfo.icDph = data.icdph;
                }
            }
        });
    }

    function checkStreetNumber() {
        let addresses = [vm.order.paymentInfo && vm.order.paymentInfo.address,
            vm.order.contactInfo && vm.order.contactInfo.address];

        return Helpers.fixAndValidateStreetNumbers(addresses);
    }

    function userLoad(lastStep) {
        onEmailChange();
        onPasswordChange(lastStep);
        if(!lastStep)
            onUserChange();
    }

    function onEmailChange() {
        vm.loginDisabled = true;
        vm.errorMessage = "";
        if(vm.order.contactInfo && vm.order.contactInfo.email) {
            vm.loginSpinner = true;
            server.isFree({email: vm.order.contactInfo.email}).then(({success, data}) => {
                vm.loginSpinner = false;
                vm.loginDisabled = false;
                if (success) {
                    vm.userExists = false;
                } else {
                    if (vm.order.contactInfo && vm.order.contactInfo.password && vm.order.contactInfo.password !== "")
                        onPasswordChange();
                    vm.userExists = true;
                }
            }).catch(vm.userExists = false);
        }
    }

    function onPasswordChange(lastStep){
        if(vm.order.contactInfo && vm.order.contactInfo.email) {
            server.login({
                email: vm.order.contactInfo.email,
                password: vm.order.contactInfo.password
            }).then(({success, data}) => {
                if (success) {
                    vm.errorMessage = null;
                    if(!lastStep)
                        onUserChange();
                    $rootScope.$broadcast('user-changed-contact');
                } else {
                    vm.errorMessage = "bad";
                }
            }).catch(vm.errorMessage = "bad");
        }
    }

    function showModalForgotPassword() {
        ModalForgotPassword(vm.order.contactInfo.email)
            .add()
            .result
            .then();
    }

    function onUserChange() {
        vm.errorMessage = null;
        server.getLoggedUser().then(onLoggedUserLoad);
    }

    function fullName(item) {
        return (item.titleBefore ? item.titleBefore + " " : "")
            .concat(item.name ? item.name + " " : "")
            .concat(item.surname ? item.surname : "")
            .concat(item.titleAfter ? ", " + item.titleAfter : "");
    }

    function onCountriesLoad(ps) {
        vm.countries = ps;
        vm.order.paymentInfo.address.country = vm.countries[0]._2;

        window.hideSpinnerWhenAllResolved();
    }

    function onPriceLoad(tp) {
        vm.cart.totalPrice = tp;
        window.hideSpinnerWhenAllResolved();
    }

    function onCitiesLoad(ps) {
        vm.cities = ps;
        window.hideSpinnerWhenAllResolved();
    }

    function onSaveAndContinueClick(steps) {
        vm.order.additionalInfo.comment = $filter("removeEmojiFilter")(vm.order.additionalInfo.comment);

        let isMissingStreetNoPrefix = checkStreetNumber();

        $timeout(() => {
            if ($scope.step2.$invalid) {
                vm.requiredMessage = true;
                vm.submitted = true;
            } else {
                if (vm.showStreetNumberModals && isMissingStreetNoPrefix) {
                    ModalStreetNumber()
                        .add()
                        .result
                        .then();

                    vm.showStreetNumberModals = false;
                } else {
                    userLoad(true);

                    if (vm.order.contactInfo && vm.order.contactInfo.address && !vm.order.contactInfo.address.country)
                        vm.order.contactInfo.address.country = vm.countries[0]._2;

                    service.setInvoiceInfo(vm.order).then(({success, data}) => {
                        if (success) {
                            if (steps) {
                                vm.valid = true;
                            } else {
                                $window.location = "krok3";
                            }
                        } else {
                            // todo validation
                        }
                    });
                }
            }
        });
    }

    function onLoggedUserLoad(user) {
        vm.loggedUser = user;
        if(!vm.order.contactInfo) {
            vm.order.contactInfo = {};
        }
        if(user) {
            vm.order.contactInfo.email = user.email;
            vm.order.contactInfo.phoneNumber = $filter("whitespaceFilter")(user.phoneNumber);
            vm.order.contactInfo.name = user.firstName;
            vm.order.contactInfo.surname = user.lastName;
            vm.order.contactInfo.address = user.address;
            vm.order.contactInfo.dateOfBirth = user.dateOfBirth;
            vm.order.contactInfo.hasCompany = user.hasCompany;
            vm.order.contactInfo.companyName = user.companyName;
            vm.order.contactInfo.ico = user.ico;
            vm.order.contactInfo.dic = user.dic;
            vm.order.contactInfo.icDph = user.icDph;

            if(!vm.order.contactInfo.hasCompany && vm.order.paymentInfo) {
                vm.order.contactInfo.ico = vm.order.paymentInfo.ico;
                vm.order.contactInfo.companyName = vm.order.paymentInfo.companyName;
                vm.order.contactInfo.dic = vm.order.paymentInfo.dic;
                vm.order.contactInfo.icDph = vm.order.paymentInfo.icdph;
            }

            if(user.firstName !== "" && user.lastName !== "") {
                vm.showContactInfoForm = true;
                vm.showContactInfoSelect = false;
            }
        }

        if(!vm.order.contactInfo.phoneNumber) {
            vm.order.contactInfo.phoneNumber = "+421";
        }
    }

    function onSaveForLaterClick() {
        ModalFinishLater(service, JSON.stringify(vm.order))
            .add(service, JSON.stringify(vm.order))
            .result
            .then();
    }

    function onOrderLoad(order) {
        if(order.invoiceInfo) {
            vm.order = order.invoiceInfo;
        } else {
            service.getStringContent().then((data) => {
                console.log(data);
                if (data) {
                    vm.order = JSON.parse(data);
                }
            });
        }
    }

    function onBackClick() {
        service.setBackContent(JSON.stringify(vm.order)).then(({success, data}) => {
            if (success) {
                $window.location = routes.virtualneSidlo.krok1;
            } else {
                // todo validation
            }
        });
    }

    function onElementFocus(id) {
        let element = $window.document.getElementById(id);
        if(element)
            element.focus();
    }
}

angular.module("firmaren")
    .controller("VirtualneSidloKrok2Controller", VirtualneSidloKrok2Controller);
}());
