;(function() {
/* @ngInject */
function DomainController(IndexService, $timeout, $scope, $filter) {
    let vm = this;

    let service = IndexService;

    vm.loaded = false;

    $scope.$watch('vm.domain.name', function () {
        getDomain();
    }, true);

    vm.setDomain = setDomain;

    function getDomain() {
        vm.loaded = false;
        if(vm.domain.name && vm.domain.name.length > 0) {
            vm.domain.name = $filter("lowercase")($filter("whitespaceFilter")(vm.domain.name.normalize("NFD").replace(/[\u0300-\u036f,]/g, "")));
            service.getDomainInfo(vm.domain.name).then((ms) => {
                vm.domain.isFree = ms;
                vm.loaded = true;
            });
        }
    }

    function setDomain() {
        vm.domain.active = !vm.domain.active;
    }

    $timeout(function() {
        getDomain();
    });
}

angular.module("firmaren")
    .component("domain", {
        templateUrl: "/app/shared/domain/domain.html",
        controller: DomainController,
        controllerAs: "vm",
        bindings: {
            domain: "="
        }
    });
}());
