;(function() {
/* @ngInject */
function ServicesController($scope, IndexService, $window, Helpers) {
    let vm = this;
    let server = IndexService;

    vm.url = "";

    server.getDashboardUrl().then(url => {vm.url = url});

    $scope.$on('service-load', function (event, data) {
        loadData(data);
    });

    vm.services = [];

    vm.getUrl = getUrl;
    vm.setStats = setStats;
    vm.setStatsToMF = setStatsToMF;
    vm.orderType = 0;

    function loadData(data) {
        vm.orderType = data.orderType;
        vm.isSingle = data.isSingle;

        if ((vm.orderType === 0 || vm.orderType === 1) && vm.isSingle) {
            vm.services = [{
                title: "Nájdite si vhodného účtovníka pre vašu firmu, <strong>so zľavou</strong>",
                url: "uctovnici",
                code: "",
                logo: "/assets/images/zlavy/uctovnici-logo.png",
                banner: "/assets/images/zlavy/uctovnici.jpg"
            }, {
                title: "Overený fakturačný program <strong>na 90 dní úplne zadarmo</strong>",
                url: "superfaktura",
                code: "",
                logo: "/assets/images/zlavy/superfaktura-nove-logo.png",
                banner: "/assets/images/zlavy/superfaktura.jpg"
            }, {
                title: "Podnikateľský účet pre SRO v Tatra banke na <strong>1 rok bez poplatku za vedenie</strong>",
                url: "tatrabanka-sro",
                code: "",
                logo: "/assets/images/zlavy/tatrabanka_logo.jpg",
                banner: "/assets/images/zlavy/tatrabanka-sro.jpg"
            }, {
                title: "<strong>2 mesiace zadarmo</strong> k ročnému hostingu The Hosting pre váš web",
                url: "websupport-hosting",
                code: "kod",
                logo: "/assets/images/zlavy/websupport-nove-logo.png",
                banner: "/assets/images/zlavy/webhosting-2.jpg"
            }, {
                title: "<strong>20% zľava</strong> na firemné pečiatky",
                url: "faxcopy-peciatky",
                code: "kod",
                logo: "/assets/images/zlavy/faxcopy_logo.jpg",
                banner: "/assets/images/zlavy/peciatka-v2.jpg"
            }, {
                title: "<strong>15% zľava</strong> na firemné vizitky",
                url: "faxcopy-vizitky",
                code: "kod",
                logo: "/assets/images/zlavy/faxcopy_logo.jpg",
                banner: "/assets/images/zlavy/vizitky.jpg"
            }, {
                title: "<strong>-5% na kurz</strong> účtovníctva pre SRO, aby ste rozumeli účtovníčke",
                url: "jaspis-podvojne-uctovnictvo",
                code: "kod",
                logo: "/assets/images/zlavy/jaspis_logo.jpg",
                banner: "/assets/images/zlavy/kurzy-uctovnictvo-4.jpg"
            }, {
                title: "<strong>Bonus 20€</strong> na Animované videá pre podnikateľov",
                url: "zacinam-podnikat",
                code: "kod",
                logo: "/assets/images/zlavy/zacinam-podnikat_logo.jpg",
                banner: "/assets/images/zlavy/zacinam-podnikat.jpg"
            }];
        } else if ((vm.orderType === 0 || vm.orderType === 1) && !vm.isSingle) {
            vm.services = [{
                title: "Nájdite si vhodného účtovníka pre vašu firmu, <strong>so zľavou</strong>",
                url: "uctovnici",
                code: "",
                logo: "/assets/images/zlavy/uctovnici-logo.png",
                banner: "/assets/images/zlavy/uctovnici.jpg"
            }, {
                title: "Overený fakturačný program <strong>na 90 dní úplne zadarmo</strong>",
                url: "superfaktura",
                code: "",
                logo: "/assets/images/zlavy/superfaktura-nove-logo.png",
                banner: "/assets/images/zlavy/superfaktura.jpg"
            },
                {
                    title: "<strong>2 mesiace zadarmo</strong> k ročnému hostingu The Hosting pre váš web",
                    url: "websupport-hosting",
                    code: "kod",
                    logo: "/assets/images/zlavy/websupport-nove-logo.png",
                    banner: "/assets/images/zlavy/webhosting-2.jpg"
                }, {
                    title: "<strong>20% zľava</strong> na firemné pečiatky",
                    url: "faxcopy-peciatky",
                    code: "kod",
                    logo: "/assets/images/zlavy/faxcopy_logo.jpg",
                    banner: "/assets/images/zlavy/peciatka-v2.jpg"
                }, {
                    title: "<strong>15% zľava</strong> na firemné vizitky",
                    url: "faxcopy-vizitky",
                    code: "kod",
                    logo: "/assets/images/zlavy/faxcopy_logo.jpg",
                    banner: "/assets/images/zlavy/vizitky.jpg"
                }, {
                    title: "Platobný terminál a kasa pre každú prevádzku",
                    url: "saltpay",
                    code: "",
                    logo: "/assets/images/zlavy/saltpay_logo.png",
                    banner: "/assets/images/zlavy/saltpay.jpeg"
                }, {
                    title: "<strong>-5% na kurz</strong> účtovníctva pre SRO, aby ste rozumeli účtovníčke",
                    url: "jaspis-podvojne-uctovnictvo",
                    code: "kod",
                    logo: "/assets/images/zlavy/jaspis_logo.jpg",
                    banner: "/assets/images/zlavy/kurzy-uctovnictvo-4.jpg"
                }, {
                    title: "<strong>Bonus 20€</strong> na Animované videá pre podnikateľov",
                    url: "zacinam-podnikat",
                    code: "kod",
                    logo: "/assets/images/zlavy/zacinam-podnikat_logo.jpg",
                    banner: "/assets/images/zlavy/zacinam-podnikat.jpg"
                }];
        } else if (vm.orderType === 2 || vm.orderType === 3 || vm.orderType === 6 || vm.orderType === 7 || vm.orderType === 11) {
            vm.services = [{
                title: "Nájdite si vhodného účtovníka pre vašu firmu, <strong>so zľavou</strong>",
                url: "uctovnici",
                code: "",
                logo: "/assets/images/zlavy/uctovnici-logo.png",
                banner: "/assets/images/zlavy/uctovnici.jpg"
            }, {
                title: "Overený fakturačný program <strong>na 90 dní úplne zadarmo</strong>",
                url: "superfaktura",
                code: "",
                logo: "/assets/images/zlavy/superfaktura-nove-logo.png",
                banner: "/assets/images/zlavy/superfaktura.jpg"
            }, {
                title: "<strong>Vedenie Živnostenského účtu<sup>TB</sup> v Tatra banke na 3 roky za 0 EUR",
                url: "tatrabanka",
                code: "",
                logo: "/assets/images/zlavy/tatrabanka_logo.jpg",
                banner: "/assets/images/zlavy/tatrabanka.jpg"
            }, {
                title: "<strong>2 mesiace zadarmo</strong> k ročnému hostingu The Hosting pre váš web",
                url: "websupport-hosting",
                code: "kod",
                logo: "/assets/images/zlavy/websupport-nove-logo.png",
                banner: "/assets/images/zlavy/webhosting-2.jpg"
            }, {
                title: "<strong>20% zľava</strong> na firemné pečiatky",
                url: "faxcopy-peciatky",
                code: "kod",
                logo: "/assets/images/zlavy/faxcopy_logo.jpg",
                banner: "/assets/images/zlavy/peciatka-v2.jpg"
            }, {
                title: "<strong>15% zľava</strong> na firemné vizitky",
                url: "faxcopy-vizitky",
                code: "kod",
                logo: "/assets/images/zlavy/faxcopy_logo.jpg",
                banner: "/assets/images/zlavy/vizitky.jpg"
            }, {
                title: "Kurz Jednoduché účtovníctvo pre živnostníkov s <strong>5% zľavou</strong>",
                url: "jaspis-jednoduche-uctovnictvo",
                code: "kod",
                logo: "/assets/images/zlavy/jaspis_logo.jpg",
                banner: "/assets/images/zlavy/kurzy-uctovnictvo-4.jpg"
            }, {
                title: "<strong>Bonus 20€</strong> na Animované videá pre podnikateľov",
                url: "zacinam-podnikat",
                code: "kod",
                logo: "/assets/images/zlavy/zacinam-podnikat_logo.jpg",
                banner: "/assets/images/zlavy/zacinam-podnikat.jpg"
            }];
        } else if (vm.orderType === 5 || vm.orderType === 10) {
            vm.services = [{
                title: "Nájdite si vhodného účtovníka pre vašu firmu, <strong>so zľavou</strong>",
                url: "uctovnici",
                code: "",
                logo: "/assets/images/zlavy/uctovnici-logo.png",
                banner: "/assets/images/zlavy/uctovnici.jpg"
            }, {
                title: "Overený fakturačný program <strong>na 90 dní úplne zadarmo</strong>",
                url: "superfaktura",
                code: "",
                logo: "/assets/images/zlavy/superfaktura-nove-logo.png",
                banner: "/assets/images/zlavy/superfaktura.jpg"
            }, {
                title: "<strong>2 mesiace zadarmo</strong> k ročnému hostingu The Hosting pre váš web",
                url: "websupport-hosting",
                code: "kod",
                logo: "/assets/images/zlavy/websupport-nove-logo.png",
                banner: "/assets/images/zlavy/webhosting-2.jpg"
            }, {
                title: "<strong>20% zľava</strong> na firemné pečiatky",
                url: "faxcopy-peciatky",
                code: "kod",
                logo: "/assets/images/zlavy/faxcopy_logo.jpg",
                banner: "/assets/images/zlavy/peciatka-v2.jpg"
            }, {
                title: "<strong>15% zľava</strong> na firemné vizitky",
                url: "faxcopy-vizitky",
                code: "kod",
                logo: "/assets/images/zlavy/faxcopy_logo.jpg",
                banner: "/assets/images/zlavy/vizitky.jpg"
            }, {
                title: "Platobný terminál a kasa pre každú prevádzku",
                url: "saltpay",
                code: "",
                logo: "/assets/images/zlavy/saltpay_logo.png",
                banner: "/assets/images/zlavy/saltpay.jpeg"
            }, {
                title: "<strong>-5% na kurz</strong> Jednoduché účtovníctvo neziskových organizácií",
                url: "jaspis-uctovnictvo-oz",
                code: "kod",
                logo: "/assets/images/zlavy/jaspis_logo.jpg",
                banner: "/assets/images/zlavy/kurzy-uctovnictvo-3.jpg"
            }, {
                title: "<strong>Bonus 20€</strong> na Animované videá pre podnikateľov",
                url: "zacinam-podnikat",
                code: "kod",
                logo: "/assets/images/zlavy/zacinam-podnikat_logo.jpg",
                banner: "/assets/images/zlavy/zacinam-podnikat.jpg"
            }];
        } else {
            vm.services = [{
                title: "Nájdite si vhodného účtovníka pre vašu firmu, <strong>so zľavou</strong>",
                url: "uctovnici",
                code: "",
                logo: "/assets/images/zlavy/uctovnici-logo.png",
                banner: "/assets/images/zlavy/uctovnici.jpg"
            }, {
                title: "Overený fakturačný program <strong>na 90 dní úplne zadarmo</strong>",
                url: "superfaktura",
                code: "",
                logo: "/assets/images/zlavy/superfaktura-nove-logo.png",
                banner: "/assets/images/zlavy/superfaktura.jpg"
            }, {
                title: "<strong>2 mesiace zadarmo</strong> k ročnému hostingu The Hosting pre váš web",
                url: "websupport-hosting",
                code: "kod",
                logo: "/assets/images/zlavy/websupport-nove-logo.png",
                banner: "/assets/images/zlavy/webhosting-2.jpg"
            }, {
                title: "<strong>20% zľava</strong> na firemné pečiatky",
                url: "faxcopy-peciatky",
                code: "kod",
                logo: "/assets/images/zlavy/faxcopy_logo.jpg",
                banner: "/assets/images/zlavy/peciatka-v2.jpg"
            }, {
                title: "<strong>15% zľava</strong> na firemné vizitky",
                url: "faxcopy-vizitky",
                code: "kod",
                logo: "/assets/images/zlavy/faxcopy_logo.jpg",
                banner: "/assets/images/zlavy/vizitky.jpg"
            }, {
                title: "Platobný terminál a kasa pre každú prevádzku",
                url: "saltpay",
                code: "",
                logo: "/assets/images/zlavy/saltpay_logo.png",
                banner: "/assets/images/zlavy/saltpay.jpeg"
            }, {
                title: "<strong>5% zľava</strong> na všetky kurzy Jaspis",
                url: "jaspis-vsetky-kurzy",
                code: "kod",
                logo: "/assets/images/zlavy/jaspis_logo.jpg",
                banner: "/assets/images/zlavy/kurzy-uctovnictvo.jpg"
            }, {
                title: "<strong>Bonus 20€</strong> na Animované videá pre podnikateľov",
                url: "zacinam-podnikat",
                code: "kod",
                logo: "/assets/images/zlavy/zacinam-podnikat_logo.jpg",
                banner: "/assets/images/zlavy/zacinam-podnikat.jpg"
            }];
        }
    }

    function getUrl(url) {
        return url.indexOf("http") > -1 ? url : vm.url + "/zlavy-a-akcie/" + url;
    }

    function setStats(url) {
        server.setStats({
            url: getUrl(url),
            source: Helpers.getUtmSource(vm.orderType),
            medium: vm.medium,
            campaign: "GridItem"
        }).then();
    }

    function setStatsToMF(campaing) {
        server.setStats({
            url: "https://www.moja.firmaren.sk",
            source: Helpers.getUtmSource(vm.orderType),
            medium: vm.medium,
            campaign: campaing
        }).then();
    }
}

angular.module("firmaren")
    .component("services", {
        templateUrl: "/app/shared/services/services.html",
        controller: ServicesController,
        controllerAs: "vm",
        bindings: {
            medium: "="
        }
    });
}());
