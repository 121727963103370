;(function() {
/* @ngInject */
function ModalCompanyName($uibModal, $rootScope) {
  let service;
  let companyName;
  let oldName;
  let domain;
  return function init(service_, companyName_, oldName_, domain_) {
    service = service_;
    companyName = companyName_;
    oldName = oldName_;
    domain = domain_;
    return { add };
  };

  function open(options = {}) {
    return $uibModal.open(angular.extend({
      templateUrl: "/app/shared/modal-company-name/modal-company-name.html",
      controller: "ModalCompanyNameController",
      controllerAs: "vm",
      bindToController: true,
      size: "lg",
      backdrop: "static",
      resolve: {
        service: () => service,
        companyName: () => companyName,
        oldName: () => oldName,
        domain: () => domain
      }
    }, options));
  }

  function add(scope = {}) {
    return open({ scope: angular.extend($rootScope.$new(true), scope) }, service, companyName, oldName, domain)
  }
}

/* @ngInject */
function ModalCompanyNameController($uibModalInstance, _, loading, service, $scope, companyName, oldName, domain) {
  let vm = this;

  vm.service = service;
  vm.companySuffix = companyName.suffix;
  if(companyName && companyName.name)
    vm.companyName = companyName.name;
  vm.oldName = oldName;
  vm.suffixes = [" s. r. o.", ", s. r. o.", ", spol. s r. o."];

  vm.domain = domain;

  vm.onCompanyNameChange = onCompanyNameChange;
  vm.onCompanySuffixChange = onCompanySuffixChange;

  function onCompanyNameChange() {
    saveCompanyName();
  }

  function onCompanySuffixChange() {
    saveCompanyName();
  }

  let saveCompanyName = _.debounce(_saveCompanyName, 1000);
  function _saveCompanyName() {
    vm.companyNameState = {loading: true};
    if(vm.companyName && vm.companySuffix) {
      let name = vm.companyName;

      let result = vm.service.setCompanyName({name: vm.companyName, suffix: vm.companySuffix});

      if (name !== "") {
        if(name.length > 1) {
          vm.companyNameState = {loading: true};

          loading(result, 1000)
            .then(({data, error}) => {
              if (data) {
                vm.companyNameState = {success: true};
                vm.domain.sk.name = vm.companyName + ".sk";
                vm.domain.com.name = vm.companyName + ".com";
              }
              else {
                if(error === "notAvailable")
                  vm.companyNameState = {notAvailable: true};
                else vm.companyNameState = {error};
              }
            })
            .catch((e) => {
              console.log(`todo: ${e}`);
              vm.companyNameState = {error};
            });
        } else vm.companyNameState = {warn: true};
      }
      else {
        vm.companyNameState = {};
      }
    }
  }

  vm.close = () => $uibModalInstance.dismiss("cancel");

  vm.submit = () => {
    if($scope.companyName.$invalid || vm.companyNameState.error) {
      vm.submitted=true;
    } else {
      $uibModalInstance.close([vm.companyName, vm.companySuffix]);
    }
  }

}

angular.module("firmaren")
  .factory("ModalCompanyName", ModalCompanyName)
  .controller("ModalCompanyNameController", ModalCompanyNameController);
}());
