;(function() {
/* @ngInject */
function ModalEndUser($uibModal, $rootScope) {
  let cities, countries, person, allPersons, changeType;

  return function init(cities_, countries_, person_, allPersons_, changeType_) {
    cities = cities_;
    countries = countries_;
    person = person_;
    allPersons = allPersons_;
    changeType = changeType_;

    return { add };
  };

  function open(options = {}) {
    return $uibModal.open(angular.extend({
      templateUrl: "/app/shared/modal-end-user/modal-end-user.html",
      controller: "ModalEndUserController",
      controllerAs: "vm",
      bindToController: true,
      size: "lg",
      backdrop: "static",
      resolve: {
        cities: () => cities,
        countries: () => countries,
        person: () => person,
        allPersons: () => allPersons,
        changeType: () => changeType
      }
    }), options);
  }

  function add(scope = {}) {
    return open({ scope: angular.extend($rootScope.$new(), scope) })
  }

}

/* @ngInject */
function ModalEndUserController($uibModalInstance, cities, countries, $timeout, person, allPersons, changeType, $scope, $filter, $window, $interval, Helpers) {
  let vm = this;

  vm.cities = cities;
  vm.countries = countries;
  vm.requiredMessage = false;
  vm.changeType = changeType;
  vm.allPersons = allPersons;
  vm.allPersons.push({
    fullName: $filter('translate')('sro.zmena.krok1.shareTransfer.modal.otherPerson'),
    index: -1
  });

  if (person) {
    vm.endUser = angular.copy(person);

    if (vm.changeType === 'remove')
      vm.endUser.change.remove = true;
    else vm.endUser.change.edit = true;

    if (!person.dateOfBirth)
      vm.endUser.dateOfBirth = {};

    if (!vm.endUser.address.country)
      vm.endUser.address.country = vm.countries[0]._2;
  } else {
    vm.endUser = {
      dateOfBirth: {},
      change: {
        add: vm.changeType && vm.changeType === 'add' ? true : false,
        edit: false,
        remove: vm.changeType && vm.changeType === 'remove' ? true : false
      }
    };
  }

  if (vm.endUser.docType)
    vm.docType = $filter('translate')('docType.' + vm.endUser.docType);

  if (!vm.endUser.type)
    vm.endUser.type = "control";

  if (!vm.endUser.endUserType)
    vm.endUser.endUserType = {
      a: true,
      b: true,
      c: true,
      d: true,
      e: true,
      f: false
    };

  vm.genders = ["male", "female"];
  vm.docTypes = ["id", "passport", "otherId"];

  if (!vm.endUser.idNumber) {
    vm.endUser.idNumber = {};
  }

  if (!vm.endUser.address) {
    vm.endUser.address = {
      country: ""
    };
    vm.endUser.address.country = vm.countries[0]._2;
  }

  if (vm.endUser.change.add) {
    if (vm.allPersons[0].newData)
      vm.person = vm.allPersons[0].newData;
    else vm.person = vm.allPersons[0];
    if (vm.person) {
      fillEndUser();
    }
    vm.isCollapsed = {
      person: true
    };
  }

  if (vm.allPersons.length === 1 || !vm.endUser.change.add) {
    vm.showOtherPerson = true;
  } else if (vm.person.address
    && vm.person.address.street
    && vm.person.address.streetNo
    && vm.person.address.city
    && vm.person.address.zipCode) {
    vm.showOtherPerson = false;
  } else {
    vm.showOtherPerson = true;
  }

  vm.onIdNumberChange = onIdNumberChange;
  vm.onResetCountry = onResetCountry;
  vm.onElementFocus = Helpers.onElementFocus;
  vm.onElementBlur = Helpers.onElementBlur;
  vm.scrollTo = Helpers.scrollInModalTo;
  vm.onZipCodeChange = onZipCodeChange;
  vm.onClick = onClick;
  vm.onDocTypeClick = onDocTypeClick;
  vm.setEndUser = setEndUser;
  vm.onPersonClick = onPersonClick;
  vm.fillName = fillName;

  function fillName(person) {
    return person.newData && person.newData.fullName ? person.newData.fullName : person.fullName
  }

  function fillEndUser() {
    if (vm.person.gender)
      vm.endUser.gender = vm.person.gender;
    else vm.endUser.gender = $filter('translate')('gender.' + vm.genders[0]);
    vm.endUser.titleBefore = vm.person.titleBefore;
    vm.endUser.name = vm.person.name;
    vm.endUser.surname = vm.person.surname;
    vm.endUser.titleAfter = vm.person.titleAfter;
    vm.endUser.address = vm.person.address;
    if (vm.endUser.address && !vm.endUser.address.country)
      vm.endUser.address.country = vm.countries[0]._2;
    if (vm.person.dateOfBirth)
      vm.endUser.dateOfBirth = vm.person.dateOfBirth;
    else vm.endUser.dateOfBirth = {};
    if (vm.person.idNumber)
      vm.endUser.idNumber = vm.person.idNumber;
    else vm.endUser.idNumber = {};
  }

  function onPersonClick() {
    vm.isCollapsed.person = !vm.isCollapsed.person;
  }

  function setEndUser(person) {
    if (person.newData)
      vm.person = person.newData;
    else vm.person = person;
    vm.isCollapsed.person = true;

    if (person.index === -1) {
      vm.endUser.titleBefore = "";
      vm.endUser.name = "";
      vm.endUser.surname = "";
      vm.endUser.titleAfter = "";
      vm.endUser.address = {};
      vm.endUser.address.country = vm.countries[0]._2;

      vm.showOtherPerson = true;
    } else {
      fillEndUser();

      if (vm.person.address
        && vm.person.address.street
        && vm.person.address.streetNo
        && vm.person.address.city
        && vm.person.address.zipCode) {
        vm.showOtherPerson = false;
      } else {
        vm.showOtherPerson = true;
      }
    }
  }

  function onDocTypeClick(item) {
    vm.docType = $filter('translate')('docType.' + item);
    vm.endUser.docType = item;
    vm.mouseoverDocType = false;
  }

  function onClick(item, elem) {
    if (elem === "day") {
      vm.endUser.dateOfBirth.day = item;
      vm.mouseoverDay = false;
    } else if (elem === "month") {
      vm.endUser.dateOfBirth.month = item;
      vm.mouseoverMonth = false;
    } else if (elem === "year") {
      vm.endUser.dateOfBirth.year = item;
      vm.mouseoverYear = false;
    }

    onIdNumberChange();
  }

  function onIdNumberChange() {
    console.log(vm.endUser.gender);
    if (vm.endUser) {
      if (vm.endUser.gender) {
        vm.endUser.idNumber.prefix = Helpers.calculateIdNumber(vm.endUser.dateOfBirth, vm.endUser.gender);
      } else {
        vm.onElementFocus("gender");
        vm.onElementBlur("gender");
        vm.scrollTo("gender");
      }
    }
  }

  function onZipCodeChange($item) {
    vm.endUser.address.zipCode = $item && $item._3;
  }

  function onResetCountry() {
    vm.endUser.address.country = "";
    $interval(function () {
      let element = $window.document.getElementById("address.country");
      if (element)
        element.focus();
    }, 100, 1);
  }

  function getFullName(person) {
    var res = "";

    if (person.titleBefore) {
      if (person.titleBefore.slice(-1) === ".")
        res += person.titleBefore + " ";
      else
        res += person.titleBefore + ". ";
    }

    res += person.name + " " + person.surname;

    if (person.titleAfter) {
      if (person.titleAfter.slice(-1) === ".")
        res += ", " + person.titleAfter;
      else
        res += ", " + person.titleAfter + ".";
    }

    return res;
  }

  vm.close = () => $uibModalInstance.dismiss("cancel");

  vm.submit = () => {
    Helpers.fixAndValidateStreetNumbers([vm.endUser && vm.endUser.address]);

    $timeout(() => {
      if ($scope.endUser.$invalid) {
        vm.submitted = true;
        vm.requiredMessage = true;
        $('.modal').animate({scrollTop: 0}, 500);
      } else {
        if (!vm.endUser.ico)
          vm.endUser.fullName = getFullName(vm.endUser);
        $uibModalInstance.close(vm.endUser);
      }
    });
  };
}

angular.module("firmaren")
  .factory("ModalEndUser", ModalEndUser)
  .controller("ModalEndUserController", ModalEndUserController);
}());
