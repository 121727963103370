;(function() {
/* @ngInject */
function ModalFounderWithCapital($uibModal, $rootScope) {
  let server, currencies, cities, countries, capital, modalType, changeType, allTransferor;

  return function init(server_, currencies_, cities_, countries_, capital_, modalType_, changeType_, allTransferor_) {
    server = server_;
    currencies = currencies_;
    cities = cities_;
    countries = countries_;
    capital = capital_;
    modalType = modalType_;
    changeType = changeType_;
    allTransferor = allTransferor_;

    return {add};
  };

  function open(options = {}) {
    return $uibModal.open(angular.extend({
      templateUrl: "/app/shared/modal-founder-with-capital/modal-founder-with-capital.html",
      controller: "ModalFounderWithCapitalController",
      controllerAs: "vm",
      bindToController: true,
      size: "lg",
      backdrop: "static",
      resolve: {
        server: () => server,
        currencies: () => currencies,
        cities: () => cities,
        countries: () => countries,
        capital: () => capital,
        modalType: () => modalType,
        changeType: () => changeType,
        allTransferor: () => allTransferor
      }
    }), options);
  }

  function add(scope = {}) {
    return open({scope: angular.extend($rootScope.$new(), scope)})
  }

}

/* @ngInject */
function ModalFounderWithCapitalController($uibModalInstance, $timeout, $filter, server, currencies, cities, countries, capital, modalType, changeType, allTransferor, $scope, $interval, $window, Helpers) {
  let vm = this;

  vm.genders = ["male", "female"];

  vm.currencies = currencies;
  vm.cities = cities;
  vm.countries = countries;
  vm.capital = capital;
  vm.modalType = modalType;
  vm.changeType = changeType;
  vm.allTransferor = allTransferor;

  vm.shareTransfer = {
    type: vm.changeType === "remove" ? "all" : "custom",
    price: 1,
    currency: vm.currencies[0].name
  };

  if (vm.allTransferor)
    vm.transferor = vm.allTransferor[0];

  vm.isCollapsed = {
    transferor: true
  };

  vm.founder = {
    type: "fo",
    executor: false,
    founder: true,
    gender: "",
    dateOfBirth: {},
    idNumber: {},
    address: {
      country: vm.countries[0]._2
    }
  };

  if (vm.modalType && vm.modalType === "transfer" && !vm.transferor)
    vm.transferor = {
      type: "fo",
      gender: "",
      idNumber: {},
      dateOfBirth: {},
      address: {
        country: vm.countries[0]._2
      }
    };

  let now = new Date();

  vm.popupOpened = false;
  vm.format = "EEEE - dd. MM. yyyy";
  vm.dateOptions = {
    showWeeks: false,
    initDate: now
  };

  vm.onAmountChange = onAmountChange;
  vm.onPercentageChange = onPercentageChange;
  vm.onZipCodeChange = onZipCodeChange;
  vm.getFullName = Helpers.getFullName;
  vm.getPercent = getPercent;
  vm.onTransferorClick = onTransferorClick;
  vm.setTransferor = setTransferor;
  vm.calculate = calculate;
  vm.onResetCountry = onResetCountry;
  vm.onElementFocus = Helpers.onElementFocus;
  vm.onElementBlur = Helpers.onElementBlur;
  vm.scrollTo = Helpers.scrollInModalTo;
  vm.onIcoChange = onIcoChange;
  vm.onClick = onClick;
  vm.onIdNumberChange = onIdNumberChange;
  vm.onClickTransferor = onClickTransferor;
  vm.onIdNumberChangeTransferor = onIdNumberChangeTransferor;
  vm.onCurrencyClick = onCurrencyClick;

  function onIcoChange() {
    server.getFinstatInfo({name: vm.founder.ico}).then(data => {
      if (data) {
        vm.founder.fullName = data.name;
        vm.founder.address = data.address;
        vm.founder.address.country = vm.countries[0]._2;
      }
    })
  }

  function onClickTransferor(item, elem) {
    if (elem === "day") {
      vm.transferor.dateOfBirth.day = item;
      vm.mouseoverDayT = false;
    } else if (elem === "month") {
      vm.transferor.dateOfBirth.month = item;
      vm.mouseoverMonthT = false;
    } else if (elem === "year") {
      vm.transferor.dateOfBirth.year = item;
      vm.mouseoverYearT = false;
    }

    onIdNumberChangeTransferor();
  }

  function onIdNumberChangeTransferor() {
    if (vm.transferor) {
      if (vm.transferor.gender) {
        vm.transferor.idNumber.prefix = Helpers.calculateIdNumber(vm.transferor.dateOfBirth, vm.transferor.gender);
      } else {
        vm.onElementFocus("genderT");
        vm.onElementBlur("genderT");
        vm.scrollTo("genderT");
      }
    }
  }

  function onCurrencyClick(item) {
    vm.shareTransfer.currency = item;
    vm.mouseoverCurrency = false;
  }


  function onClick(item, elem) {
    if (elem === "day") {
      vm.founder.dateOfBirth.day = item;
      vm.mouseoverDay = false;
    } else if (elem === "month") {
      vm.founder.dateOfBirth.month = item;
      vm.mouseoverMonth = false;
    } else if (elem === "year") {
      vm.founder.dateOfBirth.year = item;
      vm.mouseoverYear = false;
    }

    onIdNumberChange();
  }

  function onIdNumberChange() {
    if (vm.founder) {
      if (vm.founder.gender) {
        vm.founder.idNumber.prefix = Helpers.calculateIdNumber(vm.founder.dateOfBirth, vm.founder.gender);
      } else {
        vm.onElementFocus("gender");
        vm.onElementBlur("gender");
        vm.scrollTo("gender");
      }
    }
  }

  function calculate() {
    vm.shareTransfer.percent = $filter('number')(getPercent(vm.shareTransfer.euro), 2);
  }

  function setTransferor(transferor) {
    vm.transferor = transferor;
    vm.isCollapsed.transferor = true;
  }

  function onTransferorClick() {
    vm.isCollapsed.transferor = !vm.isCollapsed.transferor;
  }

  function getPercent(item) {
    return parseFloat(item) / parseFloat(vm.capital) * 100;
  }

  function onAmountChange() {
    vm.share = parseFloat(((vm.founder.depositAmount / (vm.capital + vm.founder.depositAmount)) * 100).toFixed(2));
    vm.paid = vm.founder.depositAmount;
  }

  function onPercentageChange() {
    vm.founder.depositAmount = vm.capital * (vm.share / 100);
  }

  function onZipCodeChange($item, isFounder) {
    if (isFounder)
      vm.founder.address.zipCode = $item && $item._3;
    else vm.transferor.address.zipCode = $item && $item._3;
  }

  function onResetCountry(id) {
    if (id === "address.country")
      vm.founder.address.country = "";
    else vm.transferor.address.country = "";
    $interval(function () {
      let element = $window.document.getElementById(id);
      if (element)
        element.focus();
    }, 100, 1);
  }

  vm.close = () => $uibModalInstance.dismiss("cancel");

  vm.submit = () => {
    Helpers.fixAndValidateStreetNumbers([vm.founder && vm.founder.address, vm.transferor && vm.transferor.address]);

    $timeout(() => {
      if ($scope.founder.$invalid) {
        vm.submitted = true;
      } else {
        if (vm.founder.type === "fo")
          vm.founder.fullName = vm.getFullName(vm.founder);
        let result = {
          founder: vm.founder
        };
        if (vm.shareTransfer.type === "all") {
          vm.shareTransfer.euro = vm.transferor.depositAmount;
          calculate();
        }
        if (vm.allTransferor) {
          result.founder.depositAmount = vm.shareTransfer.euro;
          result.transferor = vm.transferor.index;
        }
        if (vm.modalType)
          result.fullCapital = vm.capital;
        if (vm.modalType && vm.modalType === "transfer") {
          result.transferorData = vm.transferor;
          result.founder.depositAmount = vm.shareTransfer.euro;
        }
        result.currency = vm.shareTransfer.currency;
        result.price = vm.shareTransfer.price;
        $uibModalInstance.close(result);
      }
    });
  };

}

angular.module("firmaren")
  .factory("ModalFounderWithCapital", ModalFounderWithCapital)
  .controller("ModalFounderWithCapitalController", ModalFounderWithCapitalController);
}());
