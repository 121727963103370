;(function() {
/* @ngInject */
function ZmenaZivnostiKrok2Controller(ZmenaZivnostiKrok2Service, UserService, $window, routes, Helpers, ModalFinishLater, $scope) {
  let vm = this;
  let server = ZmenaZivnostiKrok2Service;
  vm.server = server;

  let userService = UserService;

  vm.maxStep = 2;
  vm.order = {};
  vm.requiredCheckbox = false;
  vm.donation = {};

  vm.onBackClick = onBackClick;
  vm.onFinishClick = onFinishClick;
  vm.onSaveForLaterClick = onSaveForLaterClick;
  vm.scrollTo = Helpers.scrollTo;
  vm.mergeStreetNo = Helpers.mergeStreetNo;
  vm.phoneNumberToString = Helpers.phoneNumberToString;

  vm.cart = {
    finalPrice: 0
  };

  vm.inProgress = false;

  vm.agreements = {
    agrees: false,
    representation: true
  };

  vm.upsells = {
    tb: {
      active: false,
      agree: false
    },
    superfaktura: {
      active: false,
      agree: false
    },
    znamka: {
      active: false
    }
  };

  vm.orderId = 0;
  vm.user = {};

  vm.zvyhodnena = false;
  vm.ip = "";
  vm.totalPriceWithoutDonation = 0;

  window.showSpinner(6);
  server.getOrder().then((order) => { vm.order = order; window.hideSpinnerWhenAllResolved(); });
  server.getMaxStep().then((ms) => {vm.maxStep = ms; window.hideSpinnerWhenAllResolved(); });
  server.getDonation().then((d) => vm.donation = {type: d, name: ""});
  server.getCart().then(onPriceLoad);
  server.getTotalPriceWithoutDonation().then((price => vm.totalPriceWithoutDonation = price));
  server.getOrderId().then((id) => { vm.orderId = id; window.hideSpinnerWhenAllResolved(); });
  server.getUpsell().then((upsell) => { vm.upsells = upsell; window.hideSpinnerWhenAllResolved(); });
  server.getIPAddress().then((ip) => vm.ip = ip);
  userService.getLoggedUser().then((user) => {vm.user = user; window.hideSpinnerWhenAllResolved()});
  userService.isOldUser().then((ms) => vm.zvyhodnena = ms);

  $scope.$on('on-price-change', function(event, args) {
    server.getCart().then(onPriceLoad);
  });

  $scope.$watch('vm.donation', function () {
    if(!angular.equals(vm.donation, {})) {
      server.setDonation(vm.donation.type.toString()).then(server.getCart().then(onPriceLoad));
    }

  }, true);

  function onPriceLoad(cart) {
    vm.cart = cart;

    window.hideSpinnerWhenAllResolved();
  }

  function formatDate(dateOfBirth) {
    return dateOfBirth.day.concat(".", dateOfBirth.month, ".", dateOfBirth.year);
  }

  function onBackClick() {
    $window.location = routes.zivnosti.zmeny.krok1;
  }

  function finishSave() {
    server.finish({agrees: vm.agreements.agrees, requestsRepresentation: vm.agreements.representation}).then(function (_ref) {
      var success = _ref.success;
      var data = _ref.data;
      var error = _ref.error;

      if (success) {
        $window.location = routes.zivnosti.zmeny.objednane;
      }
      vm.inProgress = false;
      window.hideSpinnerWhenAllResolved();
    }).catch(function (error) {
      vm.inProgress = false;
      window.hideSpinnerWhenAllResolved();
    });
  }

  function onFinishClick() {
    if(!vm.agreements.agrees) {
      vm.requiredCheckbox = true;
      vm.scrollTo("scroll_checkbox");
    } else {
      var dataLayer = Helpers.getDataLayer(vm.orderId,
          vm.order.destroyOrPause.value === 'destroy' ? (vm.zvyhodnena ? 'zrusenie_zivnost_zvyhodnena' : 'zrusenie_zivnost') :
              (vm.order.destroyOrPause.value === 'pause' ? (vm.zvyhodnena ? 'pozastavenie_zivnost_zvyhodnena' : 'pozastavenie_zivnost') :
                  (vm.order.destroyOrPause.value === 'renew' ? (vm.zvyhodnena ? 'obnovenie_zivnost_zvyhodnena' : 'obnovenie_zivnost') :
                      (vm.zvyhodnena ? 'zmeny_zivnost_zvyhodnena' : 'zmeny_zivnost'))),
          'zivnost', '', vm.user);
      $window.localStorage.setItem('dataLayer', JSON.stringify(dataLayer));

      vm.inProgress = true;
      window.showSpinner(1);

      if (vm.upsells && vm.upsells.tb && vm.upsells.tb.active) {
        let psc = "";

        if (vm.order.newAddressPlaceOfBussiness) {
          psc = vm.order.newAddressPlaceOfBussiness.zipCode;
        } else {
          psc = vm.order.personalInfo.address.zipCode;
        }

        let personInfo = vm.order.personalInfo;
        exponea.track( "contact_form_external ",{
          "company_name": personInfo.companyName,
          "contact_person": personInfo.name + ' ' + personInfo.surname,
          "agreement_memorandum": 1,
          "agreement_mkt": vm.upsells.tb.agree ? 1 : 0,
          "IP_address": vm.ip,
          "agreement_mkt_timestamp": vm.upsells.tb.agree && vm.upsells.tb.timestampAgree ? vm.upsells.tb.timestampAgree : "",
          "phone": vm.phoneNumberToString(personInfo.phoneNumber),
          "version":"2",
          "identify_type":"contact_form_external",
          "ico": vm.order.ico,
          "psc": psc
        }, function (param) { //successCallback return {status: "ok"}
          if (param.status && param.status === "ok") {
            vm.upsells.tb.success = true;
          } else vm.upsells.tb.success = false;

          finishSave();
        },
        function () {
          vm.upsells.tb.success = false;

          finishSave();
        });
      } else finishSave();
    }
  }

  function onSaveForLaterClick() {
    ModalFinishLater(server)
      .add(server)
      .result
      .then();
  }


}

angular.module("firmaren")
  .controller("ZmenaZivnostiKrok2Controller", ZmenaZivnostiKrok2Controller);
}());
