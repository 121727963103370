;(function() {
/* @ngInject */
function ZmenaZivnostiKrok1Controller(ZmenaZivnostiKrok1Service, $timeout, Helpers, ModalRedirectToExtension, $window, routes, _, $scope, UserService, $rootScope, ModalFinishLater, $interval, $filter, ModalForgotPassword, ModalStreetNumber, ModalInfo) {
  let vm = this;
  let service = ZmenaZivnostiKrok1Service;
  vm.service = service;

  vm.genders = ["male", "female"];

  vm.maxStep = 1;
  vm.valid = false;
  vm.order = {
    invoiceTo: {
      type: "company",
      address: {},
      sendInvoice: true
    },
    additionalInfo: {},
    personalInfo: {
      address: {},
      phoneNumber: {
        prefix : "+421"
      },
      contactMe: true,
      foreigner: {
        type: "person"
      },
      dateOfBirth: {}
    },
    contactInfo: {
      address: {}
    },
    sendTo: {
      type : "same"
    },
    destroyOrPause: {
      value: "change",
      subjects: "all"
    },
    registerDate: {
      type: "asap",
      date: {},
      until: null
    },
    files : {},
    domain: {
      sk: {
        name: "",
        isFree: false,
        active: false
      },
      com: {
        name: "",
        isFree: false,
        active: false
      }
    },
    newAddressPlaceOfBussinessType: "own"
  };

  vm.isCredit = false;

  vm.companyNameInvoice = "";
  vm.cities = [];
  vm.countries = [];
  vm.nextStep = 2;
  vm.icoError = false;
  vm.foreigner = false;
  vm.showStreetNumberModals = true;
  vm.minPricePlace = 0;
  vm.firmarenPlaces = [];

  vm.onIcoChange = onIcoChange;
  vm.onNameChange = onNameChange;
  vm.onSaveAndContinueClick = onSaveAndContinueClick;
  vm.onCityChange = onCityChange;
  vm.onEmailChange = onEmailChange;
  vm.onPasswordChange = onPasswordChange;
  vm.onSaveForLaterClick = onSaveForLaterClick;
  vm.onResetCountry = onResetCountry;
  vm.onElementFocus = Helpers.onElementFocus;
  vm.onElementBlur = Helpers.onElementBlur;
  vm.scrollTo = Helpers.scrollTo;
  vm.redirectToExtension = redirectToExtension;
  vm.onIdNumberChange = onIdNumberChange;
  vm.onClick = onClick;
  vm.showModalForgotPassword = showModalForgotPassword;
  vm.onCountryChange = onCountryChange;
  vm.onForeignerMandateChange = onForeignerMandateChange;
  vm.onCompanyNameInvoice = onCompanyNameInvoice;
  vm.onPersonalInfoClick = onPersonalInfoClick;
  vm.fullName = Helpers.fullName;
  vm.changePrice = changePrice;
  vm.onNationalityChange = onNationalityChange;
  vm.getDomainInfo = getDomainInfo;
  vm.onFirmarenPlaceChange = onFirmarenPlaceChange;

  vm.cart = {
    finalPrice: 0
  };

  vm.isCollapsed = {
    newName: true,
    address: true,
    other: true,
    destroyOrPause: true
  };

  vm.companyName = {};
  vm.phoneNumber = {};
  vm.requiredMessage = false;
  vm.order.personalInfo.gender = "";
  vm.loginSpinner = false;
  vm.loginDisabled = true;
  vm.typeOfChange = "change";

  vm.intermediateStep = Helpers.getUrlParameter('upsell') ? true : false;
  vm.upsells = {};

  if(!vm.order.personalInfo.idNumber) {
    vm.order.personalInfo.idNumber = {};
  }

  window.showSpinner(8, "change_trade_run_placeholder");
  service.getMaxStep().then( (ms) =>{ vm.maxStep = ms; window.hideSpinnerWhenAllResolved(); });
  service.getCities().then(onCitiesLoad);
  service.getCountries().then(onCountriesLoad);
  service.getFirmarenPlacesOfBusiness().then(onFirmarenPlacesOfBusinessLoad);
  service.getOrder().then(onOrderLoad);
  service.getCart().then(onPriceLoad);
  service.getInsuranceCompanies().then(onInsuranceCompaniesLoad);
  service.getTypeOfChange().then(onTypeOfChangeLoad);
  service.getUpsell().then((upsell) => { if(angular.isDefined(upsell)) vm.upsells = upsell; window.hideSpinnerWhenAllResolved(); });

  let server = UserService;

  $scope.$on('change_trade_run_placeholder', function(event, args) {
    var content = $filter("translate")("zmenaZivnosti.krok1.icoPlaceholder");
    vm.icoPlaceholder = "";

    var i = 0;

    var timer = setInterval(function () {
      if (i < content.length)
        vm.icoPlaceholder += content[i];
      else clearInterval(timer);
      i++;
      $scope.$apply();
    }, 100);
  });

  vm.loggedUser = {};
  vm.user = {};
  vm.errorMessage = null;
  vm.showData = "";
  vm.userExists = false;
  vm.contactInfo = vm.order.personalInfo;
  vm.showContactInfoForm = false;
  vm.showContactInfoSelect = true;

  userLoad();

  $scope.$on('user-changed', function(event, args) {
    userLoad();
  });

  $scope.$on('on-password-change', function(event, data) {
    if(vm.order.contactInfo)
      vm.order.contactInfo.password = data;

    onPasswordChange();
  });

  $scope.$on('on-email-change', function(event, args) {
    onEmailChange();
  });

  $scope.$on('on-price-change', function(event, args) {
    service.getCart().then(onPriceLoad);
  });

  $scope.$watch('vm.isCredit', function (newValue, oldValue) {
    if (newValue !== oldValue) {
      onCreditChange();
    }
  }, true);

  function getDomainInfo() {
    if(!vm.order.domain) {
      vm.order.domain = {
        sk: {
          name: "",
          isFree: false,
          active: false
        },
        com: {
          name: "",
          isFree: false,
          active: false
        }
      }
    }
    vm.order.domain.sk.name = !vm.order.newName.companyName ? vm.order.newName.surname + ".sk" : vm.order.newName.companyName + ".sk";
    vm.order.domain.com.name = !vm.order.newName.companyName ? vm.order.newName.surname + ".com" : vm.order.newName.companyName + ".com";
  }

  function changePrice() {
    service.changeParamPrice({value: vm.order.destroyOrPause.value}).then(onPriceLoad);
    changeDefaultRegisterDateType();
  }

  function changeDefaultRegisterDateType() {
    if (vm.order.destroyOrPause.value === "pause" && vm.order.registerDate.type === "asap") {
      vm.order.registerDate.type = "sinceUntil";
    }

    if (vm.order.destroyOrPause.value !== "pause" && vm.order.registerDate.type === "sinceUntil") {
      vm.order.registerDate.type = "asap";
    }
  }

  function onTypeOfChangeLoad(change) {
      vm.typeOfChange = change;
      vm.order.destroyOrPause.value = change;
      changePrice();
  }

  function onPersonalInfoClick(elem, item) {
    if(elem === "other") {
      vm.showContactInfoForm = true;
      vm.order.contactInfo.type = $filter('translate')('paymentInfo.other');
    } else {
      vm.showContactInfoForm = false;
      vm.contactInfo = item;
      vm.order.contactInfo.type = vm.fullName(item);
    }
    vm.mouseoverContactInfoType = false
  }

  function showModalForgotPassword() {
    ModalForgotPassword(vm.order.contactInfo.email)
        .add()
        .result
        .then();
  }

  function onClick(item, elem) {
    if(elem === "day") {
      vm.order.personalInfo.dateOfBirth.day = item;
      vm.mouseoverDay = false;
    } else if(elem === "month") {
      vm.order.personalInfo.dateOfBirth.month = item;
      vm.mouseoverMonth = false;
    } else if(elem === "year") {
      vm.order.personalInfo.dateOfBirth.year = item;
      vm.mouseoverYear = false;
    }

    onIdNumberChange();
  }

  function onNationalityChange() {
    vm.order.personalInfo.address.country = vm.order.personalInfo.nationality;
    onCountryChange();
  }

  function onCountryChange() {
    if(vm.order.personalInfo.address.country !== vm.countries[0]._2) {
      vm.foreigner = true;
      service.setForeigner("foreigner").then(service.getCart().then(onPriceLoad));
    } else {
      vm.foreigner = false;
      service.setForeigner("base").then(service.getCart().then(onPriceLoad));
    }
  }

  function onForeignerMandateChange() {
    if(vm.order.personalInfo.foreigner.type === "firmaren")
      service.setForeigner("foreigner-firmaren").then(service.getCart().then(onPriceLoad));
    else if(vm.order.personalInfo.address.country !== vm.countries[0]._2)
      service.setForeigner("foreigner").then(service.getCart().then(onPriceLoad));
    else service.setForeigner("base").then(service.getCart().then(onPriceLoad));
  }

  function onFirmarenPlaceChange() {
    if(!vm.order.newAddressFirmarenPlaceOfBussiness)
      vm.order.newAddressFirmarenPlaceOfBussiness = {};
    if(vm.order.newAddressPlaceOfBussinessType == "firmaren" && !vm.order.newAddressFirmarenPlaceOfBussiness.id) {
      vm.order.newAddressFirmarenPlaceOfBussiness = vm.firmarenPlaces[0];
    }

    if(vm.order.newAddressPlaceOfBussinessType == "firmaren") {
      vm.cart.placeOfBusinessPrice = vm.order.newAddressFirmarenPlaceOfBussiness.price;
    } else {
      vm.cart.placeOfBusinessPrice = null;
    }
  }

  function onFirmarenPlacesOfBusinessLoad(places) {
    vm.firmarenPlaces = places;

    vm.minPricePlace = Math.min.apply(null, vm.firmarenPlaces.map(function(item) {
      return item.price;
    }));

    window.hideSpinnerWhenAllResolved();
  }

  function onIdNumberChange() {
    //console.log(vm.order.personalInfo.gender);
    if(vm.order.personalInfo.gender) {
      vm.order.personalInfo.idNumber.prefix = Helpers.calculateIdNumber(vm.order.personalInfo.dateOfBirth, vm.order.personalInfo.gender);
    } else {
      vm.onElementFocus("gender");
      vm.onElementBlur("gender");
      vm.scrollTo("gender");
    }
  }

  function redirectToExtension() {
    ModalRedirectToExtension("zmenaZivnosti", "/rozsirenie-zivnosti")
      .add()
      .result
      .then();
  }

  function onIcoChange(id, elem) {
    vm.icoError = false;
    service.getFinstatInfo({name: elem}).then( data => {
      if(data) {
        if(id === "srch_bar") {
          vm.order.personalInfo = data;
          vm.order.personalInfo.foreigner = {type: "person"};
          vm.order.personalInfo.phoneNumber = {};
          if (!vm.order.personalInfo.address.country)
            vm.order.personalInfo.address.country = vm.countries[0]._2;
          if (!vm.order.personalInfo.phoneNumber.prefix)
            vm.order.personalInfo.phoneNumber.prefix = "+421";
          if (!vm.order.personalInfo.idNumber)
            vm.order.personalInfo.idNumber = {};
          if (!vm.order.personalInfo.dateOfBirth)
            vm.order.personalInfo.dateOfBirth = {};
          vm.companyNameInvoice = data.companyName;

          if(vm.order.personalInfo && vm.order.personalInfo.contactMe === undefined)
            vm.order.personalInfo.contactMe = true;
        } else {
          vm.order.invoiceTo.companyName = data.companyName;
          vm.order.invoiceTo.address = data.address;
          vm.order.invoiceTo.city = data.address.city;
          vm.order.invoiceTo.dic = data.dic;
          vm.order.invoiceTo.icdph = data.icdph;

          vm.order.invoiceTo.address.country = vm.countries[0]._2;
        }
      } else {
        if(elem.length === 8) {
          vm.icoError = true;
          vm.order.personalInfo.titleBefore = "";
          vm.order.personalInfo.titleAfter = "";
        }
      }
      vm.contactInfo = vm.order.personalInfo;
      vm.order.contactInfo.type = vm.fullName(vm.order.personalInfo);
    });
  }

  function onCitiesLoad(cities) {
    vm.cities = cities;

    window.hideSpinnerWhenAllResolved();
  }

  function onOrderLoad(order) {
    if(order) {
      vm.order = order;

      if(vm.order.newName)
        onNameChange();

      onCompanyNameInvoice();

      vm.isCollapsed = {
        newName: !order.newName,
        address: !order.newAddress && !order.newAddressPlaceOfBussiness && !order.newAddressPlace,
        other: !order.other,
        destroyOrPause: !order.destroyOrPause
      };
      userLoad();

      if(order.registerDate && order.registerDate.date)
        order.registerDate.date = new Date(order.registerDate.date);

      if(order.registerDate && order.registerDate.until)
        order.registerDate.until = new Date(order.registerDate.until);

      if(vm.order.personalInfo && vm.order.personalInfo.contactMe === undefined)
        vm.order.personalInfo.contactMe = true;
    } else {
      service.getStringContent().then((data) => {
        if (data) {
          vm.order = JSON.parse(data);

          if(vm.order.newName)
            onNameChange();

          onCompanyNameInvoice();

          if(vm.order.registerDate && vm.order.registerDate.date)
            vm.order.registerDate.date = new Date(vm.order.registerDate.date);
          if(vm.order.registerDate && vm.order.registerDate.until)
            vm.order.registerDate.until = new Date(vm.order.registerDate.until);

          if(vm.order.contactInfo) {
            vm.contactInfo.name = vm.order.contactInfo.name;
            vm.contactInfo.surname = vm.order.contactInfo.surname;
            vm.contactInfo.address = vm.order.contactInfo.address;
            vm.contactInfo.dateOfBirth = vm.order.contactInfo.dateOfBirth;
          }

          if(vm.order.personalInfo && vm.order.personalInfo.contactMe === undefined)
            vm.order.personalInfo.contactMe = true;

          userLoad();
        } else {
          service.getPlaceOfBusiness().then((pb) => {
            if(pb) {
              vm.order.newAddressFirmarenPlaceOfBussiness = pb;
              vm.order.newAddressPlaceOfBussinessType = "firmaren";
              if(!vm.order.newAddressPlaceOfBussiness)
                vm.order.newAddressPlaceOfBussiness = {};
              vm.order.newAddressPlaceOfBussiness.selected = true;
              vm.isCollapsed.address = false;
              onFirmarenPlaceChange();
            }
          });
        }
      });
    }

    window.hideSpinnerWhenAllResolved();
  }

  function onCityChange(elem, $item) {
    if(elem === "personalInfo")
      vm.order.personalInfo.address.zipCode = $item && $item._3;
    if(elem === "newAddress")
      vm.order.newAddress.zipCode = $item && $item._3;
    if(elem === "newAddressPlaceOfBussiness")
      vm.order.newAddressPlaceOfBussiness.zipCode = $item && $item._3;
    if(elem === "newAddressPlace")
      vm.order.newAddressPlace.zipCode = $item && $item._3;
    if(elem === "sendTo")
      vm.order.sendTo.address.zipCode = $item && $item._3;
    if(elem === "foreignerAddress")
        vm.order.personalInfo.foreigner.address.zipCode = $item && $item._3;
    if(elem === "foreignerPerson")
        vm.order.personalInfo.foreigner.person.address.zipCode = $item && $item._3;
    if(elem === "businessAddressCity")
        vm.order.personalInfo.foreigner.company.address.zipCode = $item && $item._3;
  }

  function onCountriesLoad(countries) {
    vm.countries = countries;
    vm.order.personalInfo.address.country = vm.countries[0]._2;
    vm.order.invoiceTo.address.country = vm.countries[0]._2;

    if(vm.order.contactInfo.address)
      vm.order.contactInfo.address.country = vm.countries[0]._2;

    window.hideSpinnerWhenAllResolved();
  }

  function onPriceLoad(cart) {
    if(vm.cart !== cart)
      vm.cart = cart;

    window.hideSpinnerWhenAllResolved();
  }

  function onCreditChange() {
    service.setCredit(vm.isCredit.toString()).then(service.getCart().then(onPriceLoad));
  }

  function onCompanyNameInvoice() {
    vm.companyNameInvoice = (vm.order.personalInfo.titleBefore ? vm.order.personalInfo.titleBefore + " " : "")
      .concat(vm.order.personalInfo.name ? vm.order.personalInfo.name + " " : "")
      .concat(vm.order.personalInfo.surname ? vm.order.personalInfo.surname : "")
      .concat(vm.order.personalInfo.titleAfter ? ", " + vm.order.personalInfo.titleAfter : "");

    vm.order.personalInfo.companyName = vm.companyNameInvoice;
  }

  function onNameChange() {
    vm.companyName.prefix = (vm.order.newName.titleBefore ? vm.order.newName.titleBefore + " " : "")
      .concat(vm.order.newName.name ? vm.order.newName.name + " " : "")
      .concat(vm.order.newName.surname ? vm.order.newName.surname : "")
      .concat(vm.order.newName.titleAfter ? ", " + vm.order.newName.titleAfter : "");

    //vm.companyName.prefix = (vm.order.newName.name ? vm.order.newName.name + " " : "").concat(vm.order.newName.surname ? vm.order.newName.surname : "");
  }

  function onInsuranceCompaniesLoad(insuranceCompanies) {
    vm.insuranceCompanies = _.map(insuranceCompanies, s=> s._2);

    window.hideSpinnerWhenAllResolved();
  }

  function checkStreetNumber() {
    let addresses = [vm.order.personalInfo && vm.order.personalInfo.address,
      vm.order.personalInfo && vm.order.personalInfo.foreigner && vm.order.personalInfo.foreigner.address,
      vm.order.personalInfo && vm.order.personalInfo.foreigner && vm.order.personalInfo.foreigner.person && vm.order.personalInfo.foreigner.person.address,
      vm.order.personalInfo && vm.order.personalInfo.foreigner && vm.order.personalInfo.foreigner.company && vm.order.personalInfo.foreigner.company.address,
      vm.order.newAddress,
      vm.order.newAddressPlaceOfBussiness,
      vm.order.newAddressPlace,
      vm.order.invoiceTo && vm.order.invoiceTo.address,
      vm.order.sendTo && vm.order.sendTo.address,
      vm.order.contactInfo && vm.order.contactInfo.address];

    return Helpers.fixAndValidateStreetNumbers(addresses);
  }

  function onSaveAndContinueClick(steps) {
    if (vm.intermediateStep){
      if (steps) {
        vm.valid = true;
      } else {
        if (vm.upsells.tb && vm.upsells.tb.active && !vm.upsells.tb.agreeFirmaren){
          $scope.step1.tbCheckboxFirmaren.required = true;
          vm.scrollTo("tbCheckbox");
        } else if (vm.upsells.superfaktura && vm.upsells.superfaktura.active && !vm.upsells.superfaktura.agree) {
          $scope.step1.superfakturaCheckbox.required = true;
          vm.scrollTo("superfakturaCheckbox");
        } else if (vm.upsells.znamka && vm.upsells.znamka.active && !vm.upsells.znamka.agree) {
          $scope.step1.znamkaCheckbox.required = true;
          vm.scrollTo("znamkaCheckbox");
        } else if (vm.upsells.saltpay && vm.upsells.saltpay.active && !vm.upsells.saltpay.agree) {
          $scope.step1.saltpayCheckbox.required = true;
          vm.scrollTo("saltpayCheckbox");
        } else if (vm.upsells.saltpay && vm.upsells.saltpay.active && !vm.upsells.saltpay.agreeFirmaren) {
          $scope.step1.saltpayCheckboxFirmaren.required = true;
          vm.scrollTo("saltpayCheckbox");
        } else {
          $scope.step1.$submitted = true;
          $window.location = routes.zivnosti.zmeny.krok2;
        }
      }
    } else {
      vm.order.additionalInfo.comment = $filter("removeEmojiFilter")(vm.order.additionalInfo.comment);
      if(vm.order.newAddressPlaceOfBussiness && vm.order.newAddressPlaceOfBussiness.selected && vm.order.newAddressPlaceOfBussiness.whichOwner)
        vm.order.newAddressPlaceOfBussiness.whichOwner = $filter("removeEmojiFilter")(vm.order.newAddressPlaceOfBussiness.whichOwner);
      if(vm.order.newAddressPlace && vm.order.newAddressPlace.selected && vm.order.newAddressPlace.whichSubject)
        vm.order.newAddressPlace.whichSubject = $filter("removeEmojiFilter")(vm.order.newAddressPlace.whichSubject);
      if(vm.order.destroyOrPause.subjectList)
        vm.order.destroyOrPause.subjectList = $filter("removeEmojiFilter")(vm.order.destroyOrPause.subjectList);
      if(vm.order.other && vm.order.other.change)
        vm.order.other.change = $filter("removeEmojiFilter")(vm.order.other.change);

      let isMissingStreetNoPrefix = checkStreetNumber();

      $timeout(() => {
        if ($scope.step1.$invalid) {
          if (vm.order.newAddress || vm.order.newAddressPlaceOfBussiness || vm.order.newAddressPlace)
            vm.isCollapsed.address = false;

          vm.requiredMessage = true;
          //scrollTo("content");
          vm.submitted = true;
        } else {
          if (vm.showStreetNumberModals && isMissingStreetNoPrefix) {
            ModalStreetNumber()
                .add()
                .result
                .then();

            vm.showStreetNumberModals = false;
          } else {
            if (!vm.showContactInfoForm) {
              vm.contactInfo = vm.order.personalInfo;
              vm.order.contactInfo.name = vm.contactInfo.name;
              vm.order.contactInfo.surname = vm.contactInfo.surname;
              vm.order.contactInfo.address = vm.contactInfo.address;
              vm.order.contactInfo.dateOfBirth = vm.contactInfo.dateOfBirth;
            }
            if (vm.order.contactInfo && vm.order.contactInfo.address && !vm.order.contactInfo.address.country)
              vm.order.contactInfo.address.country = vm.countries[0]._2;

            if (!vm.order.contactInfo.email && vm.loggedUser.email)
              vm.order.contactInfo.email = vm.loggedUser.email;

            if (vm.order.newName
                && !vm.order.newName.titleBefore
                && !vm.order.newName.name
                && !vm.order.newName.surname
                && !vm.order.newName.titleAfter
                && !vm.order.newName.companyName)
              delete vm.order.newName;

            if (vm.order.other && !vm.order.other.change)
              delete vm.order.other;

            userLoad(true);
            vm.order.contactInfo.phoneNumber = vm.order.personalInfo.phoneNumber.prefix + vm.order.personalInfo.phoneNumber.number;

            if (vm.order.destroyOrPause.value === "change"
                && !vm.order.newName
                && !vm.order.newAddress
                && !vm.order.newAddressPlaceOfBussiness
                && !vm.order.newAddressPlace
                && !vm.order.other) {
              ModalInfo("modalInfo.titleNoChange", "modalInfo.infoNoChange", "buttons.ok")
                  .add()
                  .result
                  .then();
            } else {
              service.setOrder(vm.order).then(function (_ref) {
                var success = _ref.success;
                var data = _ref.data;

                if (success) {
                  if (steps) {
                    vm.valid = true;
                  } else {
                    if (vm.order.destroyOrPause && (vm.order.destroyOrPause.value === 'renew' || vm.order.destroyOrPause.value === 'change')) {
                      vm.intermediateStep = true;
                      vm.scrollTo("upsells");
                    } else {
                      $window.location = routes.zivnosti.zmeny.krok2;
                    }
                  }
                } else {
                  if (_ref.error === "ico") {
                    $scope.step1.ico.$setValidity("mask", false);
                    vm.requiredMessage = true;
                    vm.scrollTo("content");
                  }
                }
              });
            }
          }
        }
      });
    }
  }

  function userLoad(lastStep) {
    onEmailChange();
    onPasswordChange(lastStep);
    if(!lastStep)
      onUserChange();
  }

  function onPasswordChange(lastStep){
    if(vm.order.contactInfo && vm.order.contactInfo.email) {
      server.login({
        email: vm.order.contactInfo.email,
        password: vm.order.contactInfo.password
      }).then(({success, data}) => {
        if (success) {
          vm.errorMessage = null;
          if(!lastStep)
            onUserChange();
          $rootScope.$broadcast('user-changed-contact');
        } else {
          vm.errorMessage = "bad";
        }
      }).catch(vm.errorMessage = "bad");
    }
  }

  function onUserChange() {
    vm.errorMessage = null;
    server.getLoggedUser().then(onLoggedUserLoad);
  }

  function onLoggedUserLoad(user) {
    vm.loggedUser = user;
    if(!vm.order.contactInfo) {
      vm.order.contactInfo = {};
    }
    if(user) {
      vm.order.contactInfo.email = user.email;
      vm.order.contactInfo.phoneNumber = $filter("whitespaceFilter")(user.phoneNumber);
      vm.order.contactInfo.name = user.firstName;
      vm.order.contactInfo.surname = user.lastName;
      vm.order.contactInfo.address = user.address;
      vm.order.contactInfo.dateOfBirth = user.dateOfBirth;
      vm.order.contactInfo.hasCompany = user.hasCompany;
      vm.order.contactInfo.companyName = user.companyName;
      vm.order.contactInfo.ico = user.ico;
      vm.order.contactInfo.dic = user.dic;
      vm.order.contactInfo.icDph = user.icDph;

      if(vm.loggedUser.credit > 0) {
        service.getUsedCredits().then((credits) => {
          if(credits > 0) {
            vm.isCredit = true;
          }
        });

        vm.isCollapsed.additionalInfo = false;
      }

      if(user.firstName !== "" && user.lastName !== "") {
        vm.showContactInfoForm = true;
        vm.showContactInfoSelect = false;
      }

      if(!vm.foreigner) {
        changePrice();
      }
    }

    if(!vm.order.contactInfo.phoneNumber) {
      vm.order.contactInfo.phoneNumber = "+421";
    }
  }

  function onEmailChange() {
    vm.loginDisabled = true;
    vm.errorMessage = "";
    if(vm.order.contactInfo && vm.order.contactInfo.email) {
      vm.loginSpinner = true;
      server.isFree({email: vm.order.contactInfo.email}).then(({success, data}) => {
        vm.loginSpinner = false;
        vm.loginDisabled = false;
        if (success) {
          vm.userExists = false;
        } else {
          if (vm.order.contactInfo && vm.order.contactInfo.password && vm.order.contactInfo.password !== "")
            onPasswordChange();
          vm.userExists = true;
        }
      }).catch(vm.userExists = false);
    }
  }

  function onSaveForLaterClick() {
    if(!vm.showContactInfoForm) {
      vm.order.contactInfo.name = vm.contactInfo.name;
      vm.order.contactInfo.surname = vm.contactInfo.surname;
      vm.order.contactInfo.address = vm.contactInfo.address;
      vm.order.contactInfo.dateOfBirth = vm.contactInfo.dateOfBirth;
    }

    ModalFinishLater(service, JSON.stringify(vm.order))
      .add(service, JSON.stringify(vm.order))
      .result
      .then();
  }

  function onResetCountry(id) {
    if(id === "address")
      vm.order.personalInfo.address.country = "";
    else if (id === "foreigner.nationality")
      vm.order.personalInfo.nationality = "";
    else vm.order.invoiceTo.address.country = "";
    $interval(function() {
      let element = $window.document.getElementById(id);
      if(element)
        element.focus();
    }, 100, 1);
  }
}

angular.module("firmaren")
  .controller("ZmenaZivnostiKrok1Controller", ZmenaZivnostiKrok1Controller);
}());
