;(function() {
/* @ngInject */
function ReadyMadeKrok1Controller(ReadyMadeKrok1Service, $timeout, loading, _, $filter, Helpers, $window, BusinessSubjects, $scope, ModalFinishLater, ModalCompanyName, ModalPlaceOfBusiness, ModalPersonRemove, ModalShareTransfer, ModalExecutor, ModalFounderChange, ModalBasicCapital, ModalFounderWithCapital, ModalExecutionType, ModalExecutorOtherInfo, ModalConfirmDelete, ModalEndUser, ModalInfo, ModalStreetNumber) {
  let vm = this;
  let server = ReadyMadeKrok1Service;

  vm.subjects = BusinessSubjects;

  vm.maxStep = 1;
  vm.valid = false;
  vm.cart = { };

  let ico = Helpers.getUrlParameter('ico');

  vm.order = {
    companyName: {},
    shareTransfer: [],
    date: {},
    isSelf: false
  };
  vm.suffixes = [" s. r. o.", ", s. r. o.", ", spol. s r. o."];
  vm.executionTypes = ["onTheirOwn", "together", "other"];
  vm.order.companyName.suffix = vm.suffixes[0];
  vm.cities = [];
  vm.changes = {
    executor: false,
    founder: false,
    endUser: false
  };

  vm.angular = angular;
  vm.showAddBtnFounder = false;
  vm.showAddBtnExecutor = false;

  vm.onIcoChange = onIcoChange;
  vm.onSaveAndContinueClick = onSaveAndContinueClick;
  vm.onSaveForLaterClick = onSaveForLaterClick;
  vm.onChangeCompanyNameClick = onChangeCompanyNameClick;
  vm.onChangePlaceOfBusinessClick = onChangePlaceOfBusinessClick;
  vm.blurChangeBtn = blurChangeBtn;
  vm.newAddressNumbers = newAddressNumbers;
  vm.getPercent = getPercent;
  vm.onAddExecutiveManagerClick = onAddExecutiveManagerClick;
  vm.getFullName = Helpers.getFullName;
  vm.onEditExecutiveManagerClick = onEditExecutiveManagerClick;
  vm.formatDate = Helpers.formatDate;
  vm.scrollTo = Helpers.scrollTo;
  vm.onEditShareTransfer = onEditShareTransfer;
  vm.onEditBasicCapitalClick = onEditBasicCapitalClick;
  vm.onAddFounderWithCapitalClick = onAddFounderWithCapitalClick;
  vm.onAddFounderWithShareTransfer = onAddFounderWithShareTransfer;
  vm.onEditExecutionTypeClick = onEditExecutionTypeClick;
  vm.onAddExecutorOtherInfoClick = onAddExecutorOtherInfoClick;
  vm.confirmClearData = confirmClearData;
  vm.clearData = clearData;
  vm.onAddOrEditEndUserClick = onAddOrEditEndUserClick;

  vm.isCollapsed = {
    executors: true,
    companyName: true,
    place: true,
    subjects: true,
    founders: true,
    capital: true,
    other: true,
    firmarenAddress: true,
    clerks: true,
    endUser: true,
    executionType: true
  };
  vm.requiredMessage = false;
  vm.nextStep = 2;
  vm.finstatSpinner = false;
  vm.disableElement = true;
  vm.placeOfBusinessKinds = ["area", "flat", "otherBuilding", "house", "garage"];

  vm.newAddress = {};
  vm.showStreetNumberModals = true;

  window.showSpinner(11);
  server.isFreeReadyMade({name: ico}).then( (data) =>{ if(!data) $window.location = "/predaj-firmy"; });
  server.getCart().then(onCartLoad);
  server.getFirmarenPlacesOfBusiness().then(onFirmarenPlacesOfBusinessLoad);
  server.getSelectedSubjects().then(onSelectedSubjectsLoad);
  server.getSubjects().then( (subjects) => { BusinessSubjects.init(subjects);  window.hideSpinnerWhenAllResolved(); });
  server.getOrder().then(onOrderLoad);
  server.getPlaceOfBusiness().then(onPlaceOfBusinessLoad);
  server.getCities().then(onCitiesLoad);
  server.getCountries().then( (cs) => { vm.countries = cs; window.hideSpinnerWhenAllResolved() });
  server.getCompaniesTypes().then( (ct) => { vm.companiesTypes = ct; window.hideSpinnerWhenAllResolved() });
  server.getCurrencies().then(data => { vm.currencies = data; window.hideSpinnerWhenAllResolved() });
  server.getMaxStep().then(onMaxStepLoad);

  if(ico && ico.length === 8) {
    vm.order.ico = ico;
    onIcoChange();
  }

  function clearData(item) {
    if (item === "name")
      vm.order.companyName = {
        suffix: vm.suffixes[0]
      };
    if (item === "place") {
      vm.placeOfBusiness = {
        type: "own",
        kind: $filter("translate")('placeOfBusinessKind.' + vm.placeOfBusinessKinds[0])
      };
      vm.firmarenAddress = vm.firmarenPlacesOfBusiness[0];
      vm.newAddress = {};
    }

    if (item === "executors" && vm.order.persons) {
      let index = vm.order.persons.length - 1;
      while (index >= 0) {
        if (vm.order.persons[index].executor && vm.order.persons[index].newData)
          delete vm.order.persons[index].newData;
        if (vm.order.persons[index].executor && vm.order.persons[index].change.add)
          vm.order.persons.splice(index, 1);
        else if (vm.order.persons[index].executor)
          vm.order.persons[index].change = {
            add: false,
            edit: false,
            remove: false,
            removeClerk: vm.order.persons[index].change.removeClerk,
            transferor: false,
            assign: false
          };
        index -= 1;
      }
      clearDate();
      vm.changes.executor = false;
      vm.showAddBtnExecutor = false;
    }
    if (item === "founders" && vm.order.persons) {
      let index = vm.order.persons.length - 1;
      while (index >= 0) {
        if (vm.order.persons[index].founder && vm.order.persons[index].oldDepositAmount) {
          vm.order.persons[index].depositAmount = vm.order.persons[index].oldDepositAmount;
          delete vm.order.persons[index].oldDepositAmount;
        }
        if (vm.order.persons[index].founder && vm.order.persons[index].change.add)
          vm.order.persons.splice(index, 1);
        else if (vm.order.persons[index].founder)
          vm.order.persons[index].change = {
            add: false,
            edit: false,
            remove: vm.order.persons[index].change.remove,
            removeClerk: vm.order.persons[index].change.removeClerk,
            transferor: false,
            assign: false
          };
        index -= 1;
      }
      clearDate();
      vm.order.shareTransfer = [];
      vm.changes.founder = false;
      vm.showAddBtnFounder = false;
    }
    if (item === "clerks" && vm.order.persons) {
      let index = vm.order.persons.length - 1;
      while (index >= 0) {
        if (vm.order.persons[index].clerk && vm.order.persons[index].newData)
          delete vm.order.persons[index].newData;
        if (vm.order.persons[index].clerk && vm.order.persons[index].change.add)
          vm.order.persons.splice(index, 1);
        else if (vm.order.persons[index].clerk)
          vm.order.persons[index].change = {
            add: false,
            edit: false,
            remove: vm.order.persons[index].change.remove,
            removeClerk: false,
            transferor: false,
            assign: false
          };
        index -= 1;
      }
      vm.changes.clerk = false;
    }
    if (item === "endUser" && vm.order.rpvsPersons) {
      let index = vm.order.rpvsPersons.length - 1;
      while (index >= 0) {
        if (vm.order.rpvsPersons[index] && vm.order.rpvsPersons[index].newData)
          delete vm.order.rpvsPersons[index].newData;
        if (vm.order.rpvsPersons[index] && vm.order.rpvsPersons[index].change.add)
          vm.order.rpvsPersons.splice(index, 1);
        else if (vm.order.rpvsPersons[index])
          vm.order.rpvsPersons[index].change = {
            add: false,
            edit: false
          };
        index -= 1;
      }
      vm.changes.endUser = false;
    }
    if (item === "subjects")
      vm.subjects.clear();
    if (item === "capital")
      vm.order.basicCapital = vm.order.oldBasicCapital;
    if (item === "executionType") {
      delete vm.order.executionType;
      delete vm.order.executionTypeOther;
    }
  }

  function getPercent(item) {
    return parseFloat(item)/parseFloat(vm.order.basicCapital)*100;
  }

  function newAddressNumbers() {
    if(vm.newAddress.address) {
      if (vm.placeOfBusiness && vm.placeOfBusiness.type === "firmaren") {
        return vm.newAddress.address.streetNo2 ? vm.newAddress.address.streetNo2 + "/" + vm.newAddress.address.streetNo : vm.newAddress.address.streetNo;
      } else {
        return vm.newAddress.address.streetNoPrefix ? vm.newAddress.address.streetNoPrefix + "/" + vm.newAddress.address.streetNo : vm.newAddress.address.streetNo;
      }
    }
  }

  function blurChangeBtn() {
    let elements = $window.document.getElementsByClassName("btn_changes");
    angular.forEach(elements, function(value, _) {
      value.blur();
    });
  }

  function confirmClearData(item){
    ModalConfirmDelete("confirmClearData.title").add().result.then(x => {
      clearData(item);
    });
  }

  function clearDate() {
    let changeWithDates = $filter('filter')(vm.order.persons, function (person) {
      return person.change.add || person.change.remove;
    });
    if(changeWithDates.length === 0)
      vm.order.date = {};
  }

  function onAddExecutorOtherInfoClick() {
    let executors = $filter('filter')(vm.order.persons, function (person) {
      return person.executor && !person.change.remove
    });

    ModalExecutorOtherInfo(executors, vm.cities)
      .add()
      .result
      .then(result => {
        result.forEach(function (executor) {
          vm.order.persons[executor.index].dateOfBirth = executor.dateOfBirth;
          vm.order.persons[executor.index].idNumber = executor.idNumber;
        })
      });
  }

  function onAddOrEditEndUserClick(person) {
    let companySuffix = vm.companiesTypes.split(";");

    let allPersons = [];

    if(vm.order.persons) {
      vm.order.persons.forEach(person => {
        if (companySuffix.filter(n => person.fullName.includes(n)).length === 0 && person.change.add)
          allPersons.push(person)
      });
    }

    ModalEndUser(vm.cities, vm.countries, person && person.newData ? person.newData : person, allPersons, 'add')
      .add()
      .result
      .then(result => {
        if(person) {
          vm.order.rpvsPersons[person.index].newData = result;
          vm.order.rpvsPersons[person.index].change = result.change;
        } else {
          let newPerson = result;
          newPerson.index = vm.order.rpvsPersons.length;
          vm.order.rpvsPersons.push(newPerson);
        }

        vm.changes.endUser = true;
      });
  }

  function onEditBasicCapitalClick() {
    let allFounders = $filter('filter')(vm.order.persons, {'founder': true});

    allFounders.forEach(function (founder) {
      if(founder.change.edit && !founder.newData)
        founder.diffAmount = Math.abs(founder.depositAmount - founder.oldDepositAmount);
      if(!founder.change.transferor && !founder.change.assign && founder.change.add)
        founder.diffAmount = parseInt(founder.depositAmount);
    });

    ModalBasicCapital(vm.order.oldBasicCapital, vm.order.basicCapital, allFounders)
      .add()
      .result
      .then(result => {
        // z modalu pole spolocnikov s rozdielmi vkladov
        result.founder.forEach(function (founder) {
          vm.order.persons[founder.index].oldDepositAmount = vm.order.persons[founder.index].depositAmount;
          if(vm.order.basicCapital < result.newAmount)
            vm.order.persons[founder.index].depositAmount = parseFloat(vm.order.persons[founder.index].depositAmount) + founder.diffAmount;
          else vm.order.persons[founder.index].depositAmount = parseFloat(vm.order.persons[founder.index].depositAmount) - founder.diffAmount;
          vm.order.persons[founder.index].change.edit = true;
        });

        // nove zakladne imanie
        vm.order.basicCapital = result.newAmount;
      });
  }

  function onEditShareTransfer() {

    let allTransferror = $filter('filter')(vm.order.persons, {'founder': true});

    ModalShareTransfer(server, vm.currencies, allTransferror, [], 5000, vm.cities, vm.countries, true)
      .add()
      .result
      .then(persons => {
        persons.forEach(result => {

          let otherPerson = result.otherPerson;
          otherPerson.founder = true;
          otherPerson.executor = false;
          otherPerson.clerk = false;
          otherPerson.change = {
            add: true,
            edit: false,
            remove: false,
            removeClerk: false,
            transferor: false,
            assign: true
          };
          otherPerson.oldDepositAmount = 0;
          otherPerson.depositAmount = result.shareTransfer.newPrice;
          if (vm.order.persons)
            otherPerson.index = vm.order.persons.length;
          else {
            vm.order.persons = [];
            otherPerson.index = 0;
          }
          vm.order.persons.push(otherPerson);

          allTransferror.forEach(transferror => {
            let shareTransfer = {
              transferor: transferror.index,
              assign: vm.order.persons.length - 1,
              ownEuro: parseInt(transferror.depositAmount),
              transferEuro: parseInt(transferror.depositAmount),
              allEuro: parseInt(result.shareTransfer.newPrice),
              price: parseInt(result.shareTransfer.price),
              currency: result.shareTransfer.currency
            };

            vm.order.shareTransfer.push(shareTransfer);

            vm.order.persons[transferror.index].oldDepositAmount = vm.order.persons[transferror.index].depositAmount;
            vm.order.persons[transferror.index].depositAmount = 0;
            vm.order.persons[transferror.index].change.transferor = true;
          });

          vm.changes.founder = true;
          vm.showAddBtnFounder = true;
        });
      });

    blurChangeBtn();
  }

  function onEditExecutiveManagerClick(executor, isExecutor, changeType) {

    ModalExecutor(vm.cities, vm.countries, vm.order.date, isExecutor, changeType)
      .add()
      .result
      .then(em => {
        if(isExecutor) {
          let person = em;
          person.founder = false;
          person.executor = true;
          person.clerk = false;
          person.change = {
            add: true,
            edit: false,
            remove: false,
            removeClerk: false,
            transferor: false,
            assign: false
          };
          if(vm.order.persons)
            person.index = vm.order.persons.length;
          else {
            vm.order.persons = [];
            person.index = 0;
          }
          vm.order.persons.push(person);

          executor.change.remove = true;
          vm.changes.executor = true;
          vm.showAddBtnExecutor = true;
          if (executor.founder)
            vm.changes.founder = true;
        } else {
          executor.newData = em;
          executor.change.edit = true;

          vm.changes.clerk = true;
        }

      });

    blurChangeBtn();
  }

  function onAddExecutiveManagerClick(isExecutor, changeType) {
    ModalExecutor(vm.cities, vm.countries, vm.order.date, isExecutor, changeType)
      .add()
      .result
      .then(em => {
        let person = em;
        person.founder = false;
        person.executor = !(!isExecutor);
        person.clerk = !isExecutor;
        person.change = {
          add: !changeType ? true : false,
          edit: changeType && changeType === "edit" ? true : false,
          remove: changeType && changeType === "remove" && isExecutor ? true : false,
          removeClerk: changeType && changeType === "remove" && !isExecutor ? true : false,
          transferor: false,
          assign: false
        };
        if(vm.order.persons)
          person.index = vm.order.persons.length;
        else {
          vm.order.persons = [];
          person.index = 0;
        }
        vm.order.persons.push(person);
        if(isExecutor) {
          vm.changes.executor = true;
          if(em.date)
            vm.order.date = em.date;
        } else vm.changes.clerk = true;
      });

    blurChangeBtn();
  }

  // v pripade ze je jednoosobova spolocnost sa otvara tento modal pre zrusenie aj prevod podielu
  // preto je potrebny typ
  function onAddFounderWithShareTransfer(changeType) {
    let allTransferor = $filter('filter')(vm.order.persons, person => {
      return person.founder && parseInt(person.depositAmount) > 0;
    });

    ModalFounderWithCapital(server, vm.currencies, vm.cities, vm.countries, vm.order.basicCapital, undefined, changeType, allTransferor)
      .add()
      .result
      .then(em => {
        let founder = em.founder;
        founder.founder = true;
        founder.executor = false;
        founder.clerk = false;
        founder.change = {
          add: true,
          edit: false,
          remove: false,
          removeClerk: false,
          transferor: false,
          assign: true
        };
        if(vm.order.persons)
          founder.index = vm.order.persons.length;
        else {
          vm.order.persons = [];
          founder.index = 0;
        }
        vm.order.persons.push(founder);

        // v pripade ze nie su dotiahnute udaje z finstatu prevodca vyplna vsetky udaje
        if(em.transferorData) {
          let transferor = em.transferorData;
          transferor.founder = true;
          transferor.executor = false;
          transferor.clerk = false;
          transferor.change = {
            add: false,
            edit: false,
            remove: false,
            removeClerk: false,
            transferor: true,
            assign: false
          };
          transferor.fullName = vm.getFullName(transferor);
          transferor.index = vm.order.persons.length;
          transferor.depositAmount = 5000;
          vm.order.persons.push(transferor);

          em.transferor = transferor.index;

          vm.order.oldBasicCapital = vm.order.basicCapital = em.fullCapital;
        }

        vm.order.persons[em.transferor].oldDepositAmount = vm.order.persons[em.transferor].depositAmount;
        vm.order.persons[em.transferor].depositAmount = parseFloat(vm.order.persons[em.transferor].oldDepositAmount) - parseFloat(founder.depositAmount);
        vm.order.persons[em.transferor].change.transferor = true;

        let shareTransfer = {
          transferor: em.transferor,
          assign: founder.index,
          ownEuro: parseInt(vm.order.persons[em.transferor].oldDepositAmount),
          transferEuro: parseInt(founder.depositAmount),
          allEuro: parseInt(founder.depositAmount),
          price: parseInt(em.price),
          currency: em.currency
        };
        vm.order.shareTransfer.push(shareTransfer);

        vm.changes.founder = true;
      });

    blurChangeBtn();
  }

  function onAddFounderWithCapitalClick() {
    ModalFounderWithCapital(server, vm.currencies, vm.cities, vm.countries, vm.order.basicCapital)
      .add()
      .result
      .then(em => {
        let founder = em.founder;
        founder.founder = true;
        founder.executor = false;
        founder.clerk = false;
        founder.change = {
          add: true,
          edit: false,
          remove: false,
          removeClerk: false,
          transferor: false,
          assign: false
        };
        if(vm.order.persons)
          founder.index = vm.order.persons.length;
        else {
          vm.order.persons = [];
          founder.index = 0;
        }
        if(em.fullCapital)
          vm.order.oldBasicCapital = vm.order.basicCapital = em.fullCapital;
        vm.order.persons.push(founder);
        vm.changes.founder = true;
        vm.order.basicCapital = parseFloat(vm.order.basicCapital) + founder.depositAmount;
      });

    blurChangeBtn();
  }

  function onEditExecutionTypeClick() {
    ModalExecutionType(
      vm.order.executionType ? vm.order.executionType : vm.executionType,
      vm.order.executionTypeOther ? vm.order.executionTypeOther : vm.executionTypeOther)
      .add()
      .result
      .then(e => {
        vm.order.executionType = e.executionType;
        vm.order.executionTypeOther = e.executionTypeOther;
      });

    blurChangeBtn();
  }

  function onChangeCompanyNameClick() {
    ModalCompanyName(server, vm.order.companyName, vm.order.name)
      .add()
      .result
      .then(n => {vm.order.companyName.name = n[0]; vm.order.companyName.suffix = n[1]});

    blurChangeBtn();
  }

  function onChangePlaceOfBusinessClick() {
    ModalPlaceOfBusiness(vm.placeOfBusiness, vm.cities, vm.firmarenPlacesOfBusiness, vm.firmarenAddress, vm.order.address)
      .add()
      .result
      .then(onChangePlace);

    blurChangeBtn();
  }

  function onChangePlace(item) {
    vm.placeOfBusiness = item[0];
    vm.firmarenAddress = item[1];

    vm.newAddress.address = vm.placeOfBusiness && vm.placeOfBusiness.type === "firmaren" ? vm.firmarenAddress : vm.placeOfBusiness.address;
  }

  function onMaxStepLoad(ms) {
    vm.maxStep = ms;

    if(vm.maxStep === 1) {
      ModalInfo("sro.readymade.krok1.label", "sro.readymade.krok1.info", "buttons.ok")
        .add()
        .result
        .then();
    }

    window.hideSpinnerWhenAllResolved();
  }

  function onCartLoad(c) {
    if(c) {
      vm.cart.price = c.totalPrice;
      vm.cart.placeOfWork = c.placeOfBusinessPrice;
      vm.cart.size = c.subjectCount;
    }
    window.hideSpinnerWhenAllResolved();
  }

  function onOrderLoad(c) {
    if(c) {
      vm.order = c;

      if(!vm.order.date)
        vm.order.date = {};
      if(!vm.order.companyName)
        vm.order.companyName = {
          suffix: vm.suffixes[0]
        };

      onIcoChange();
    } else {
      server.getStringContent().then((data) => {
        if (data) {
          vm.order = JSON.parse(data).data;
          vm.isCollapsed = {
            executors: false,
            companyName: false,
            place: false,
            founders: false,
            endUser: false
          };

          vm.disableElement = false;

          vm.placeOfBusiness = JSON.parse(data).placeOfBusiness;
          if (vm.placeOfBusiness.type === "firmaren") {
            vm.placeOfBusiness = { type: JSON.parse(data).placeOfBusiness.type };
            vm.firmarenAddress = JSON.parse(data).placeOfBusiness;
          }
          vm.newAddress.address = vm.placeOfBusiness && vm.placeOfBusiness.type === "firmaren" ? vm.firmarenAddress : vm.placeOfBusiness.address;
          onIcoChange();
        }
      });
    }

    window.hideSpinnerWhenAllResolved();
  }

  function onCitiesLoad(c) {
    vm.cities = c;

    window.hideSpinnerWhenAllResolved();
  }

  function onIcoChange() {
    server.getFinstatFullInfo({name: vm.order.ico}).then( data => {
      if(data) {
        vm.order.name = data.name;
        vm.order.address = data.address;
        vm.order.zapisRegister = data.zapisRegister;
        vm.order.icDph = data.icdph;
        vm.order.dic = data.dic;
        // vm.ico.success = true;
        if(!vm.order.basicCapital)
          vm.order.basicCapital = parseFloat(data.basicCapital);
        else vm.order.basicCapital = parseFloat(vm.order.basicCapital);
        vm.order.oldBasicCapital = parseFloat(data.basicCapital);
        if(!vm.order.removeSubjects)
          vm.order.removeSubjects = data.subjects;

        if(!vm.order.persons) {
          vm.order.persons = [];
          angular.forEach(data.persons, function (person, index) {
            person.index = index;
            person.executor = false;
            person.founder = false;
            person.clerk = false;
            person.change = {
              add: false,
              edit: false,
              remove: false,
              removeClerk: false,
              transferor: false,
              assign: false
            };
            angular.forEach(person.functions, function (f) {
              if (f.description === "Konateľ")
                person.executor = true;
              if (f.description === "Spoločník") {
                person.founder = true;
              }
              if (f.description === "Prokúra")
                person.clerk = true;
            });
            delete person.functions;
            vm.order.persons.push(person);
          });
        } else if(vm.order.persons) {
          angular.forEach(vm.order.persons, function (person) {
            if(person.founder && (person.change.add || person.change.edit || person.change.remove || person.change.assign || person.change.transferor))
              vm.changes.founder = true;
            if(person.executor && (person.change.add || person.change.edit || person.change.remove))
              vm.changes.executor = true;
            if(person.clerk && (person.change.add || person.change.edit || person.change.removeClerk))
              vm.changes.clerk = true;
          });
        }

        if(!vm.order.rpvsPersons) {
          vm.order.rpvsPersons = [];
          angular.forEach(data.rpvsPersons, function (person, index) {
            person.index = index;
            person.change = {
              add: false,
              edit: false
            };
            angular.forEach(person.functions, function (f) {
              if (f.type === "Konečný užívateľ výhod podľa RPVS") {
                delete person.functions;
                vm.order.rpvsPersons.push(person);
              }
            });
          });
        } else if(vm.order.rpvsPersons) {
          angular.forEach(vm.order.rpvsPersons, function (person) {
            if(person.change.add || person.change.edit)
              vm.changes.endUser = true;
          });
        }

        if(!angular.equals(vm.order.date, {}))
          vm.order.date = new Date(vm.order.date);

        if(data.executionType === $filter("translate")('sro.zalozenie.krok2.executionType.check.' + vm.executionTypes[0]))
          vm.executionType = vm.executionTypes[0];
        else if(data.executionType === $filter("translate")('sro.zalozenie.krok2.executionType.check.' + vm.executionTypes[1]))
          vm.executionType = vm.executionTypes[1];
        else {
          vm.executionType = vm.executionTypes[2];
          vm.executionTypeOther = data.executionType;
        }

        vm.isCollapsed.executors = false;
        vm.isCollapsed.companyName = false;
        vm.isCollapsed.place = false;
        vm.isCollapsed.founders = false;
        vm.isCollapsed.endUser = false;

      }

      vm.disableElement = !(vm.order.ico.length === 8);
    }).catch(function() {
      vm.disableElement = true;
    });
  }

  function onPlaceOfBusinessLoad(pb) {
    if (angular.isDefined(pb)) {
      if (pb.type === "own") {
        vm.placeOfBusiness = pb;
      }
      else if (pb.type === "firmaren") {
        vm.placeOfBusiness = { type: pb.type };
        vm.firmarenAddress = pb;
      }
      vm.newAddress.address = vm.placeOfBusiness && vm.placeOfBusiness.type === "firmaren" ? vm.firmarenAddress : vm.placeOfBusiness.address;
    }

    window.hideSpinnerWhenAllResolved();
  }

  function onFirmarenPlacesOfBusinessLoad(ps) {
    vm.firmarenPlacesOfBusiness = ps;
    vm.firmarenAddress = vm.firmarenPlacesOfBusiness[0];

    window.hideSpinnerWhenAllResolved();
  }

  BusinessSubjects.onChange($scope, ($event, subjects) => onSelectionChange(subjects));

  function onSelectedSubjectsLoad(ss) {
    if (angular.isDefined(ss)) {
      vm.isCollapsed.subjects = false;
      BusinessSubjects.select(ss.subjects);
    }

    window.hideSpinnerWhenAllResolved();
  }

  function onSelectionChange(subjects) {
    let subject = _.last(subjects);
    if(subject && subject.responsiblePerson && vm.order.persons && subject.responsiblePerson.type !== 'other')
      vm.order.persons[subject.responsiblePerson.type].otherInfo = subject.responsiblePerson.person.otherInfo;
    server.setSelectedSubjects({ subjects })
      .then(onCartLoad);
  }

  function checkStreetNumber() {
    let addresses = [vm.placeOfBusiness && vm.placeOfBusiness.address];

    vm.order.persons.forEach(person => {
      if (person.change.add || person.change.edit) {
        if (person.newData) {
          addresses.push(person.newData.address);
        } else {
          person.address;
        }
      }
    });

    vm.order.rpvsPersons.forEach(person => {
      if (person.change.add || person.change.edit) {
        if (person.newData) {
          addresses.push(person.newData.address);
        } else {
          person.address;
        }
      }
    });

    return Helpers.fixAndValidateStreetNumbers(addresses);
  }

  function onSaveAndContinueClick(steps) {
    let executorsWithoutOtherInfo = $filter('filter')(vm.order.persons, function (person) {
      return person.executor && !person.change.remove && !person.idNumber && !person.dateOfBirth
    });

    if(vm.order.other && vm.order.other.comment)
      vm.order.other.comment = $filter("removeEmojiFilter")(vm.order.other.comment);

    let isMissingStreetNoPrefix = checkStreetNumber();

    $timeout(() => {
      if ($scope.step1.$invalid || (!vm.changes.founder || !vm.changes.executor || !vm.changes.endUser)) {
        vm.requiredMessage = true;
        vm.submitted = true;

        if (!vm.changes.executor) vm.scrollTo("executor_element");
        else if (!vm.changes.founder) vm.scrollTo("founder_element");
        else if (!vm.changes.endUser) vm.scrollTo("enduser_element");
      } else {
        if (vm.showStreetNumberModals && isMissingStreetNoPrefix) {
          ModalStreetNumber()
              .add()
              .result
              .then();

          vm.showStreetNumberModals = false;
        } else {
          vm.order.onlyEndUser = false;
          if (vm.subjects.selected.length > 0 && executorsWithoutOtherInfo.length > 0) {
            onAddExecutorOtherInfoClick();
          } else {
            console.log(vm.order);
            server.saveAndContinue(vm.order).then(({success, data}) => {
              if (success) {
                let placeOfBusiness = vm.placeOfBusiness && vm.placeOfBusiness.type === "firmaren" ? vm.firmarenAddress : vm.placeOfBusiness;
                if (placeOfBusiness && (placeOfBusiness.street || placeOfBusiness.address)) {
                  server.setPlaceOfBusiness(placeOfBusiness)
                      .then(() => {
                        if (steps) {
                          vm.valid = true;
                        } else {
                          $window.location = "krok2";
                        }
                      })
                      .catch((e) => console.log(`todo: ${e}`));
                } else {
                  if (steps) {
                    vm.valid = true;
                  } else {
                    $window.location = "krok2";
                  }
                }
              }
            });
          }
        }
      }
    });
  }

  function onSaveForLaterClick() {
    let order = {
      data : vm.order,
      placeOfBusiness: vm.placeOfBusiness
    };

    ModalFinishLater(server, JSON.stringify(order))
      .add(server, JSON.stringify(order))
      .result
      .then();
  }
}

angular.module("firmaren")
  .controller("ReadyMadeKrok1Controller", ReadyMadeKrok1Controller);
}());
