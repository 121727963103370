;(function() {
/* @ngInject */
function ModalShareTransfer($uibModal, $rootScope) {
  let server, currencies, founder, allAssign, basicCapital, cities, countries, isRemove;

  return function init(server_, currencies_, founder_, allAssign_, basicCapital_, cities_, countries_, isRemove_) {
    server = server_;
    currencies = currencies_;
    founder = founder_;
    allAssign = allAssign_;
    basicCapital = basicCapital_;
    cities = cities_;
    countries = countries_;
    isRemove = isRemove_;

    return { add };
  };

  function open(options = {}) {
    return $uibModal.open(angular.extend({
      templateUrl: "/app/shared/modal-share-transfer/modal-share-transfer.html",
      controller: "ModalShareTransferController",
      controllerAs: "vm",
      bindToController: true,
      size: "lg",
      backdrop: "static",
      resolve: {
        server: () => server,
        currencies: () => currencies,
        founder: () => founder,
        allAssign: () => allAssign,
        basicCapital: () => basicCapital,
        cities: () => cities,
        countries: () => countries,
        isRemove: () => isRemove
      }
    }, options));
  }

  function add(scope = {}) {
    return open({ scope: angular.extend($rootScope.$new(true), scope) })
  }
}

/* @ngInject */
function ModalShareTransferController($scope, server, $filter, $window, $uibModalInstance, currencies, founder, basicCapital, allAssign, cities, countries, isRemove, Helpers) {
  let vm = this;

  vm.currencies = currencies;
  vm.genders = ["male", "female"];
  vm.cities = cities;
  vm.countries = countries;

  //vm.founder = founder;
  vm.isRemove = isRemove;

  if(!angular.isArray(founder)) {
    vm.founder = {
      fullName: founder.fullName,
      depositAmount: founder.depositAmount
    };
  } else {
    var name = "";
    var deposit = 0;
    founder.forEach(f => {
      if(name === "")
        name = f.fullName;
      else name = name + " + " + f.fullName;

      deposit = deposit + parseInt(f.depositAmount);
    });
    vm.founder = {
      fullName: name,
      depositAmount: deposit
    };
  }

  vm.allAssign = allAssign;
  vm.allAssign.push({
    fullName: $filter('translate')('sro.zmena.krok1.shareTransfer.modal.otherPerson'),
    index: -1
  });
  vm.basicCapital = basicCapital;
  vm.shareTransfer = {
    price: 1,
    currency: vm.currencies[0].name
  };

  if(vm.isRemove)
    vm.shareTransfer.type = "all";
  else vm.shareTransfer.type = "custom";

  vm.otherPerson = {
    type: "fo",
    dateOfBirth: {},
    idNumber: {},
    address: {
      country: vm.countries[0]._2
    }
  };

  vm.isCollapsed = {
    assign: true
  };

  let minDate = new Date();

  vm.persons = [];

  addPerson();

  vm.days = createArray(1,31, false);
  vm.months = createArray(1,12, false);
  vm.years = createArray(1900, minDate.getFullYear() - 18, true);

  vm.getPercent = getPercent;
  vm.setAssign = setAssign;
  vm.calculate = calculate;
  vm.onClick = onClick;
  vm.onElementFocus = Helpers.onElementFocus;
  vm.onElementBlur = Helpers.onElementBlur;
  vm.scrollTo = Helpers.scrollInModalTo;
  vm.getFullName = Helpers.getFullName;
  vm.onIcoChange = onIcoChange;
  vm.addPerson = addPerson;
  vm.maxAmount = maxAmount;

  function maxAmount(index) {
    if(index === 0) {
      return vm.founder.depositAmount - 750;
    } else {
      let amount = 0;
      vm.persons.slice(0, index).forEach(person => {
        if(person.shareTransfer.euro)
          amount = amount + parseFloat(person.shareTransfer.euro);
      });

      return vm.founder.depositAmount - amount;
    }
  }

  function addPerson() {
    let assignIds = [];
    vm.persons.forEach(person => {
      if(person.assign && person.assign.index !== -1) {
        assignIds.push(person.assign.index);
      }
    });

    let allAssign = vm.allAssign.filter(a => !assignIds.includes(a.index));

    let assign = angular.copy(allAssign[0]);
    vm.shareTransfer.newPrice = assign.depositAmount;
    vm.shareTransfer.newPercent = getPercent(assign.depositAmount);

    vm.persons.push({
      otherPerson: angular.copy(vm.otherPerson),
      shareTransfer: angular.copy(vm.shareTransfer),
      assign: assign,
      isCollapsed: angular.copy(vm.isCollapsed),
      showOtherPerson: allAssign.length === 1,
      allAssign: allAssign
    });
  }

  function onIcoChange(index) {
    server.getFinstatInfo({name: vm.persons[index].otherPerson.ico}).then(data => {
      if (data) {
        vm.persons[index].otherPerson.fullName = data.name;
        vm.persons[index].otherPerson.address = data.address;
        vm.persons[index].otherPerson.address.country = vm.countries[0]._2;
      }
    })
  }

  function createArray(start, end, reverse) {
    let array = [];
    for(var i = start; i <= end; i++) {
      array.push(i);
    }
    if(reverse)
      return array.reverse();
    else return array;
  }

  function onClick(item, elem, index) {
    if (elem === "day") {
      vm.persons[index].otherPerson.dateOfBirth.day = item;
      vm.persons[index].mouseoverDay = false;
    } else if (elem === "month") {
      vm.persons[index].otherPerson.dateOfBirth.month = item;
      vm.persons[index].mouseoverMonth = false;
    } else if (elem === "year") {
      vm.persons[index].otherPerson.dateOfBirth.year = item;
      vm.persons[index].mouseoverYear = false;
    } else if (elem === "currency") {
      vm.persons[index].shareTransfer.currency = item;
      vm.persons[index].mouseoverCurrency = false;
    }

    onIdNumberChange(index);
  }

  function onIdNumberChange(index) {
    if (vm.persons[index].otherPerson) {
      if (vm.persons[index].otherPerson.gender) {
        vm.persons[index].otherPerson.idNumber.prefix = Helpers.calculateIdNumber(vm.persons[index].otherPerson.dateOfBirth, vm.persons[index].otherPerson.gender);
      } else {
        vm.onElementFocus("gender" + index);
        vm.onElementBlur("gender" + index);
        vm.scrollTo("gender" + index);
      }
    }
  }

  function calculate(index) {
    vm.persons[index].shareTransfer.percent = $filter('number')(getPercent(vm.persons[index].shareTransfer.euro), 2);
    vm.persons[index].shareTransfer.newPrice = parseFloat(vm.persons[index].assign.depositAmount) + parseFloat(vm.persons[index].shareTransfer.euro);
    vm.persons[index].shareTransfer.newPercent = getPercent(vm.persons[index].shareTransfer.newPrice);
  }

  function setAssign(assign, index) {
    vm.persons[index].assign = assign;
    vm.persons[index].isCollapsed.assign = true;

    if(assign.index === -1)
      vm.persons[index].showOtherPerson = true;
    else vm.persons[index].showOtherPerson = false;
  }

  function getPercent(item) {
    return parseFloat(item)/parseFloat(vm.basicCapital)*100;
  }

  vm.close = () => $uibModalInstance.dismiss("cancel");

  vm.submit = () => {
    if ($scope.shareTransfer.$invalid) {
      vm.submitted = true;
    } else {
      vm.persons.forEach((person, index) => {
        if(person.shareTransfer.type === "all") {
          person.shareTransfer.euro = vm.founder.depositAmount;
          calculate(index);
        }

        if(person.assign.index === -1) {
          person.otherPerson.depositAmount = person.shareTransfer.euro;
          person.shareTransfer.newPrice = person.shareTransfer.euro;
          person.shareTransfer.newPercent = person.shareTransfer.percent;
          if (!person.otherPerson.ico)
            person.otherPerson.fullName = vm.getFullName(person.otherPerson);
        }

        person.assign = person.assign.index;
      });

      $uibModalInstance.close(vm.persons);
    }
  };

}

angular.module("firmaren")
  .factory("ModalShareTransfer", ModalShareTransfer)
  .controller("ModalShareTransferController", ModalShareTransferController);
}());
