;(function() {
/* @ngInject */
function ZalozenieOZKrok2Controller(ZalozenieOZKrok2Service, Helpers, $timeout, $rootScope, $filter, UserService, $window, ModalFinishLater, $interval, $scope, ModalForgotPassword, ModalStreetNumber) {
  let vm = this;
  let server = ZalozenieOZKrok2Service;
  vm.server = server;
  let service = UserService;

  vm.genders = ["male", "female"];
  //vm.gender = $filter("translate")('gender.' + vm.genders[0]);

  vm.order = {
    persons : [{name:"", idNumber: {}, address: {}, dateOfBirth: {}},
      {name:"", idNumber: {}, address: {}, dateOfBirth: {}},
      {name:"", idNumber: {}, address: {}, dateOfBirth: {}}],
    contactInfo: {
      address: {}
    },
    orderInfo: {
      type: "company",
      address: {}
    },
    sendInvoice: true,
    isSelf: false,
    additionalInfo: {}
  };
  vm.requiredMessage = false;
  vm.nextStep = 3;
  vm.valid = false;
  vm.contactInfo = vm.order.persons[0];
  vm.loginSpinner = false;
  vm.loginDisabled = true;
  vm.showContactInfoForm = false;
  vm.showContactInfoSelect = true;
  vm.showStreetNumberModals = true;

  vm.isCollapsed = {
    person0: false,
    person1: true,
    person2: true,
  };

  vm.loadingSteps = false;

  vm.isCredit = false;

  vm.intermediateStep = Helpers.getUrlParameter('upsell') ? true : false;
  vm.upsells = {};

  function getSelfBoolean() {
    let res = $window.location.pathname.split("/");
    if (res[1] === "zalozenie-obcianskeho-zdruzenia")
      vm.order.isSelf = false;
    else vm.order.isSelf = true;
    server.setSelfBoolean(vm.order.isSelf.toString()).then();
  }

  getSelfBoolean();

  vm.onSaveAndContinueClick = onSaveAndContinueClick;
  vm.onBackClick = onBackClick;
  vm.onSaveForLaterClick = onSaveForLaterClick;
  vm.onResetCountry = onResetCountry;
  vm.onElementFocus = Helpers.onElementFocus;
  vm.onElementBlur = Helpers.onElementBlur;
  vm.onCityChange = onCityChange;
  vm.scrollTo = Helpers.scrollTo;
  vm.onEmailChange = onEmailChange;
  vm.onPasswordChange = onPasswordChange;
  vm.onIcoChange = onIcoChange;
  vm.onIdNumberChange = onIdNumberChange;
  vm.showModalForgotPassword = showModalForgotPassword;
  vm.onClick = onClick;
  vm.onPersonalInfoClick = onPersonalInfoClick;
  vm.fullName = Helpers.fullName;
  vm.onNameChange = onNameChange;

  window.showSpinner(6);
  server.getMaxStep().then( (ms) => { vm.maxStep = ms; window.hideSpinnerWhenAllResolved(); });
  server.getCities().then(onCitiesLoad);
  server.getPersons().then(onPersonsLoad);
  server.getCountries().then(onCountriesLoad);
  server.getCart().then(onCartLoad);
  server.getUpsell().then((upsell) => { if(angular.isDefined(upsell)) vm.upsells = upsell; window.hideSpinnerWhenAllResolved(); });

  userLoad();

  $scope.$on('user-changed', function(event, args) {
    userLoad();
  });

  $scope.$on('on-password-change', function(event, data) {
    if(vm.order.contactInfo)
      vm.order.contactInfo.password = data;

    onPasswordChange();
  });

  $scope.$on('on-email-change', function(event, args) {
    onEmailChange();
  });

  $scope.$on('on-price-change', function(event, args) {
    server.getCart().then(onCartLoad);
  });

  $scope.$watch('vm.isCredit', function (newValue, oldValue) {
    if (newValue !== oldValue) {
      onCreditChange();
    }
  }, true);

  function contactInfoToOrder() {
    if(!vm.showContactInfoForm) {
      vm.order.contactInfo.name = vm.contactInfo.name;
      vm.order.contactInfo.surname = vm.contactInfo.surname;
      vm.order.contactInfo.address = vm.contactInfo.address;
      vm.order.contactInfo.dateOfBirth = vm.contactInfo.dateOfBirth;
    }
  }

  function onNameChange(index) {
    if(index === 0) {
      if(!vm.order.contactInfo)
        vm.order.contactInfo = {};
      vm.order.contactInfo.type = vm.fullName(vm.order.persons[0]);
      vm.contactInfo = vm.order.persons[0];
    }
  }

  function onPersonalInfoClick(elem, item) {
    if(elem === "other") {
      vm.showContactInfoForm = true;
      vm.order.contactInfo.type = $filter('translate')('paymentInfo.other');
    } else {
      vm.showContactInfoForm = false;
      vm.contactInfo = item;
      vm.order.contactInfo.type = vm.fullName(item);
      vm.order.contactInfo.address = { country: vm.countries[0]._2 };
    }
    vm.mouseoverContactInfoType = false
  }

  function onClick(item, $index, elem) {
    if(elem === "day") {
      vm.order.persons[$index].dateOfBirth.day = item;
        vm.order.persons[$index].mouseoverDay = false;
    } else if(elem === "month") {
      vm.order.persons[$index].dateOfBirth.month = item;
        vm.order.persons[$index].mouseoverMonth = false;
    } else if(elem === "year") {
      vm.order.persons[$index].dateOfBirth.year = item;
        vm.order.persons[$index].mouseoverYear = false;
    } else {
      vm.order.persons[$index].gender = $filter('translate')('gender.' + item);
        vm.order.persons[$index].mouseover = false;
    }
    onIdNumberChange($index);
  }

  function onIdNumberChange($index) {
    if(vm.order.persons[$index].gender) {
      if(vm.order) {
        vm.order.persons[$index].idNumber.prefix = Helpers.calculateIdNumber(vm.order.persons[$index].dateOfBirth, vm.order.persons[$index].gender);
      }
    } else {
      vm.onElementFocus("gender" + $index);
      vm.onElementBlur("gender" + $index);
      vm.scrollTo("gender" + $index);
    }
  }

  function showModalForgotPassword() {
    ModalForgotPassword(vm.order.contactInfo.email)
        .add()
        .result
        .then();
  }

  let minDate = new Date();
  vm.years = createArray(1900, minDate.getFullYear(), true);

  function createArray(start, end, reverse) {
    let array = [];
    for(var i = start; i <= end; i++) {
      array.push(i);
    }
    if(reverse)
      return array.reverse();
    else return array;
  }

  function onIcoChange() {
    //console.log("ico");
    server.getFinstatInfo({name: vm.order.orderInfo.idNumber}).then( data => {
      if(data) {
        //console.log(data);
        vm.order.orderInfo.name = data.name;
        vm.order.orderInfo.address = data.address;
        vm.order.orderInfo.dic = data.dic;
        vm.order.orderInfo.icdph = data.icdph;
      }
      vm.order.orderInfo.address.country = vm.countries[0]._2;
    });
  }

  function userLoad(lastStep) {
    onEmailChange();
    onPasswordChange(lastStep);
    if(!lastStep)
      onUserChange();
  }

  function onGetOzInfo(ps) {
    vm.ozName = ps.name;
    vm.order.contactInfo.email = ps.placeOfBusiness.email;
    userLoad();
  }

  function onPasswordChange(lastStep){
    if(vm.order.contactInfo && vm.order.contactInfo.email) {
      service.login({
        email: vm.order.contactInfo.email,
        password: vm.order.contactInfo.password
      }).then(({success, data}) => {
        if (success) {
          vm.errorMessage = null;
          if(!lastStep)
            onUserChange();
          $rootScope.$broadcast('user-changed-contact');
        } else {
          vm.errorMessage = "bad";
        }
      }).catch(vm.errorMessage = "bad");
    }
  }

  function onUserChange() {
    vm.errorMessage = null;
    service.getLoggedUser().then(onLoggedUserLoad);
  }

  function onLoggedUserLoad(user) {
    vm.loggedUser = user;
    if(!vm.order.contactInfo) {
      vm.order.contactInfo = {};
    }
    if(user) {
      vm.order.contactInfo.email = user.email;
      vm.order.contactInfo.phoneNumber = $filter("whitespaceFilter")(user.phoneNumber);
      vm.order.contactInfo.name = user.firstName;
      vm.order.contactInfo.surname = user.lastName;
      vm.order.contactInfo.address = user.address;
      vm.order.contactInfo.dateOfBirth = user.dateOfBirth;
      vm.order.contactInfo.hasCompany = user.hasCompany;
      vm.order.contactInfo.companyName = user.companyName;
      vm.order.contactInfo.ico = user.ico;
      vm.order.contactInfo.dic = user.dic;
      vm.order.contactInfo.icDph = user.icDph;

      if(vm.loggedUser.credit > 0) {
        server.getUsedCredits().then((credits) => {
          if(credits > 0) {
            vm.isCredit = true;
          }
        });
      }

      if(user.firstName !== "" && user.lastName !== "") {
        vm.showContactInfoForm = true;
        vm.showContactInfoSelect = false;
      }
    }

    if(!vm.order.contactInfo.phoneNumber) {
      vm.order.contactInfo.phoneNumber = "+421";
    }
  }

  function onEmailChange() {
    vm.loginDisabled = true;
    vm.errorMessage = "";
    if(vm.order.contactInfo && vm.order.contactInfo.email) {
      vm.loginSpinner = true;
      service.isFree({email: vm.order.contactInfo.email}).then(({success, data}) => {
        vm.loginSpinner = false;
        vm.loginDisabled = false;
        if (success) {
          vm.userExists = false;
        } else {
          if (vm.order.contactInfo && vm.order.contactInfo.password && vm.order.contactInfo.password !== "")
            onPasswordChange();
          vm.userExists = true;
        }
      }).catch(vm.userExists = false);
    }
  }

  function onSaveForLaterClick() {
    contactInfoToOrder();
    ModalFinishLater(server, JSON.stringify(vm.order))
      .add(server, JSON.stringify(vm.order))
      .result
      .then();
  }

  function onCityChange($item, $index, isPesron) {
    if(isPesron)
      vm.order.persons[$index].address.zipCode = $item && $item._3;
    else vm.order.orderInfo.address.zipCode = $item && $item._3;
  }

  function onResetCountry(isPesron, $index) {
    if(isPesron)
      vm.order.persons[$index].address.country = "";
    else vm.order.orderInfo.address.country = "";

    $interval(function() {
      let element = $window.document.getElementById("address.country" + $index);
      if(element)
        element.focus();
    }, 100, 1);
  }

  function onCitiesLoad(ps) {
    vm.cities = ps;

    window.hideSpinnerWhenAllResolved();
  }

  function onCountriesLoad(ps) {
    vm.countries = ps;

    if(!vm.order.orderInfo.address.country)
      vm.order.orderInfo.address.country = vm.countries[0]._2;

    if(vm.order.contactInfo.address)
      vm.order.contactInfo.address.country = vm.countries[0]._2;

    window.hideSpinnerWhenAllResolved();
  }

  function checkStreetNumber() {
    let addresses = [vm.order.orderInfo && vm.order.orderInfo.address,
      vm.order.persons && vm.order.persons[0] && vm.order.persons[0].address,
      vm.order.persons && vm.order.persons[1] && vm.order.persons[1].address,
      vm.order.persons && vm.order.persons[2] && vm.order.persons[2].address,
      vm.order.contactInfo && vm.order.contactInfo.address];

    return Helpers.fixAndValidateStreetNumbers(addresses);
  }

  function onSaveAndContinueClick(steps) {
    if (vm.intermediateStep){
      if (steps) {
        vm.valid = true;
      } else {
        if (vm.upsells.superfaktura && vm.upsells.superfaktura.active && !vm.upsells.superfaktura.agree) {
          $scope.step2.superfakturaCheckbox.required = true;
          vm.scrollTo("superfakturaCheckbox");
        } else if (vm.upsells.znamka && vm.upsells.znamka.active && !vm.upsells.znamka.agree) {
          $scope.step2.znamkaCheckbox.required = true;
          vm.scrollTo("znamkaCheckbox");
        } else if (vm.upsells.saltpay && vm.upsells.saltpay.active && !vm.upsells.saltpay.agree) {
          $scope.step2.saltpayCheckbox.required = true;
          vm.scrollTo("saltpayCheckbox");
        } else if (vm.upsells.saltpay && vm.upsells.saltpay.active && !vm.upsells.saltpay.agreeFirmaren) {
          $scope.step2.saltpayCheckboxFirmaren.required = true;
          vm.scrollTo("saltpayCheckbox");
        } else {
          $scope.step2.$submitted = true;
          $window.location = "krok3";
        }
      }
    } else {
      vm.order.additionalInfo.comment = $filter("removeEmojiFilter")(vm.order.additionalInfo.comment);
      let isMissingStreetNoPrefix = checkStreetNumber();

      $timeout(() => {
        if ($scope.step2.$invalid) {
          vm.isCollapsed = {
            person0: false,
            person1: false,
            person2: false,
          };

          vm.requiredMessage = true;
          //scrollTo("content");
          vm.submitted = true;
        } else {
          if (vm.showStreetNumberModals && isMissingStreetNoPrefix) {
            ModalStreetNumber()
                .add()
                .result
                .then();

            vm.showStreetNumberModals = false;
          } else {
            contactInfoToOrder();
            if (vm.order.contactInfo && vm.order.contactInfo.address && !vm.order.contactInfo.address.country)
              vm.order.contactInfo.address.country = vm.countries[0]._2;

            userLoad(true);
            server.setPersons(vm.order).then(() => {
              if (steps) {
                vm.valid = true;
              } else {
                vm.intermediateStep = true;
                vm.scrollTo("upsells");
              }
            })
          }
        }
      });
    }
  }

  function onBackClick() {
    if(vm.intermediateStep) {
      vm.intermediateStep = false;
    } else {
      contactInfoToOrder();
      server.setBackContent(JSON.stringify(vm.order)).then(({success, data}) => {
        if (success) {
          $window.location = "krok1";
        } else {
          // todo validation
        }
      });
    }
  }

  function onPersonsLoad(value) {
    if (value)
      vm.order = value;
    if(!value) {
      server.getStringContent().then((data) => {
          if(data) {
            vm.order = JSON.parse(data);
            onNameChange(0);
            userLoad();
            if(vm.order.contactInfo) {
              vm.contactInfo.name = vm.order.contactInfo.name;
              vm.contactInfo.surname = vm.order.contactInfo.surname;
              vm.contactInfo.address = vm.order.contactInfo.address;
              vm.contactInfo.dateOfBirth = vm.order.contactInfo.dateOfBirth;
            }
          }
      });
    } else {
      userLoad();
    }

    if(!vm.order.orderInfo) {
      vm.order.orderInfo = {
        type: "company",
        address: {}
      }
    }

    getSelfBoolean();
    server.getOzInfo().then(onGetOzInfo);

    vm.loadingSteps = true;
    window.hideSpinnerWhenAllResolved();
  }

  function onCreditChange() {
    server.setCredit(vm.isCredit.toString()).then(server.getCart().then(onCartLoad));
  }

  function onCartLoad(cart) {
    vm.cart = cart;
    server.getPrice().then(onGetPrice);

    window.hideSpinnerWhenAllResolved();
  }

  function onGetPrice(p) {
    vm.cart.totalPrice = p;

    window.hideSpinnerWhenAllResolved();
  }
}

angular.module("firmaren")
  .controller("ZalozenieOZKrok2Controller", ZalozenieOZKrok2Controller);
}());
