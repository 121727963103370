;(function() {
/* @ngInject */
function ModalFounder($uibModal, $rootScope) {
  let cities, countries, capital, validate, existDepositAdmin, isSinglePerson, server;

  return function init(cities_, countries_, capital_, validate_, existDepositAdmin_, isSinglePerson_, server_) {
    cities = cities_;
    countries = countries_;
    capital = capital_;
    validate = validate_;
    existDepositAdmin = existDepositAdmin_;
    isSinglePerson = isSinglePerson_;
    server = server_;

    return { add, edit };
  };

  function open(options = {}, founder = { type: "person", isExecutiveManager: true , isDepositAdministrator: !existDepositAdmin, address: null}) {
    return $uibModal.open(angular.extend({
      templateUrl: "/app/shared/modal-founder/modal-founder.html",
      controller: "ModalFounderController",
      controllerAs: "vm",
      bindToController: true,
      size: "lg",
      backdrop: "static",
      resolve: {
        cities: () => cities,
        countries: () => countries,
        founder: () => founder,
        capital: () => capital,
        validate: () => validate,
        existDepositAdmin: () => existDepositAdmin,
        isSinglePerson: () => isSinglePerson,
        server: () => server
      }
    }, options));
  }

  function add(scope = {}) {
    return open({ scope: angular.extend($rootScope.$new(true), scope) });
  }

  function edit(founder, scope = {}) {
    return open({ scope: angular.extend($rootScope.$new(true), scope) }, founder);
  }
}

/* @ngInject */
function ModalFounderController(Helpers, $uibModalInstance, $filter, cities, countries, founder, capital, validate,
                                $interval, $window, $scope, existDepositAdmin, $timeout, isSinglePerson, server) {
  let vm = this;

  vm.genders = ["male", "female"];
  vm.docTypes = ["id", "passport", "otherId"];

  vm.cities = cities;
  vm.countries = countries;
  vm.founder = founder;
  vm.capital = capital;
  vm.validate = validate;
  vm.existDepositAdmin = existDepositAdmin;
  vm.isSinglePerson = isSinglePerson;
  vm.founderTypes= vm.isSinglePerson ? ["person"] : ["person", "company"];
  vm.founderHelp = $filter('translate')('founder.type.' + vm.founder.type);
  vm.requiredMessage = false;
  if(!vm.founder.representativePerson) {
    vm.founder.representativePerson = {
      isDepositAdministrator: !vm.existDepositAdmin
    };
  }
  //vm.founder.gender = $filter("translate")('gender.' + vm.genders[0]);

  if(vm.founder.address === null) {
    vm.founder.address = {};
    vm.founder.address.country = vm.countries[0]._2;

    if(!vm.isSinglePerson){
      vm.founder.capital = vm.capital.amount - vm.validate.amount;
      if(vm.founder.capital > 0)
        onAmountChange();
    }
    else {
      vm.founder.share = 100;
      vm.founder.paid = vm.capital.amount;
      vm.founder.capital = vm.capital.amount;
    }
  }

  if(!vm.founder.idNumber) {
    vm.founder.idNumber = {};
  }

  vm.isCollapsed = {
    isOpen: false
  };

  if(vm.founder.docType)
    onDocTypeClick(vm.founder.docType);

  let minDate = new Date();

  vm.days = createArray(1,31, false);
  vm.months = createArray(1,12, false);
  vm.years = createArray(1900, minDate.getFullYear() - 18, true);

  function createArray(start, end, reverse) {
    let array = [];
    for(var i = start; i <= end; i++) {
      array.push(i);
    }
    if(reverse)
      return array.reverse();
    else return array;
  }

  vm.close = () => $uibModalInstance.dismiss("cancel");
  vm.submit = () => {
    Helpers.fixAndValidateStreetNumbers([vm.founder && vm.founder.address]);

    $timeout(() => {
      if ($scope.founder.$invalid) {
        vm.isCollapsed.isOpen = true;
        vm.requiredMessage = true;
        $('.modal').animate({scrollTop: 0}, 500);
        vm.submitted = true;
      } else {
        $uibModalInstance.close(vm.founder)
      }
    });
  };

  vm.onIdNumberChange = onIdNumberChange;
  vm.onAmountChange = onAmountChange;
  vm.onPercentageChange = onPercentageChange;
  vm.onResetCountry = onResetCountry;
  vm.onElementFocus = Helpers.onElementFocus;
  vm.onElementBlur = Helpers.onElementBlur;
  vm.scrollTo = Helpers.scrollInModalTo;
  vm.onZipCodeChange = onZipCodeChange;
  vm.onFormerSurnameChange = onFormerSurnameChange;
  vm.onClick = onClick;
  vm.onDocTypeClick = onDocTypeClick;
  vm.onIcoChange = onIcoChange;

  vm.formerSurnameChange = false;

  function onIcoChange(ico) {
    server.getFinstatInfo({name: ico}).then( data => {
      if(data) {
        vm.founder.companyName = data.name;
        vm.founder.address = data.address;
      }
      vm.founder.address.country = vm.countries[0]._2;
    });
  }

  function onDocTypeClick(item) {
    vm.docType = $filter('translate')('docType.' + item);
    vm.founder.docType = item;
    vm.mouseoverDocType = false;
  }

  function onClick(item, elem) {
    if(!vm.founder.dateOfBirth)
        vm.founder.dateOfBirth = {};
    if(elem === "day") {
      vm.founder.dateOfBirth.day = item;
      vm.mouseoverDay = false;
    } else if(elem === "month") {
      vm.founder.dateOfBirth.month = item;
      vm.mouseoverMonth = false;
    } else if(elem === "year") {
      vm.founder.dateOfBirth.year = item;
      vm.mouseoverYear = false;
    } else if(elem === "docType") {
      vm.founder.docType = $filter('translate')('docType.' + item);
      vm.mouseoverDocType = false;
    }

    onIdNumberChange();
  }

  function onFormerSurnameChange() {
    vm.formerSurnameChange = true;
  }

  function onZipCodeChange($item) {
    vm.founder.address.zipCode = $item && $item._3;
  }

  function onResetCountry() {
    vm.founder.address.country = "";
    $interval(function() {
      let element = $window.document.getElementById("address");
      if(element)
        element.focus();
    }, 100, 1);
  }

  function onAmountChange() {
    vm.founder.share = (vm.founder.capital / vm.capital.amount) * 100;
    vm.founder.paid = vm.founder.capital;
  }

  function onPercentageChange() {
    vm.founder.capital = Math.round(vm.capital.amount * (vm.founder.share / 100));
    vm.founder.paid = vm.founder.capital;
  }

  function onIdNumberChange() {
  //$scope.step2.dateOfBirthYear.$validate();
    if(vm.founder.gender) {
      if(vm.founder) {
        vm.founder.idNumber.prefix = Helpers.calculateIdNumber(vm.founder.dateOfBirth, vm.founder.gender);
      }
    } else {
      vm.onElementFocus("gender");
      vm.onElementBlur("gender");
      vm.scrollTo("gender");
    }
  }
}

angular.module("firmaren")
  .factory("ModalFounder", ModalFounder)
  .controller("ModalFounderController", ModalFounderController);
}());
