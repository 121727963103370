;(function() {
function Helpers($window, $filter, removeDiacritics) {

  let date = {};

  return {
    calculateIdNumber,
    calculateDateOfBirth,
    getUtmSource,
    mergeStreetNo,
    onElementFocus,
    onElementBlur,
    scrollTo,
    scrollInModalTo,
    fullName,
    getFullName,
    dateToString,
    idNumberToString,
    phoneNumberToString,
    formatDate,
    formatStreet,
    formatCityZipCode,
    formatAddress,
    formatParentName,
    getUrlParameter,
    getFileName,
    getBurzaUtmCampaign,
    getDataLayer,
    fixAndValidateStreetNumbers
  };

  function fixAndValidateStreetNumbers(addresses) {
    var result = false;

    addresses.forEach(address => {
      if(address && address.streetNoPrefix && (!address.streetNo || address.streetNo === "")) {
        address.streetNo = address.streetNoPrefix;
      }
      if (address && address.streetNo && (!address.streetNoPrefix || address.streetNoPrefix === "")) {
        result = true;
      }
    });

    return result;
  }

  function getUrlParameter(name) {
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    var results = regex.exec(location.search);
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
  };

  function getFileName(fileName) {
    let fileNames = ["splnomocnenie", "rozhodnutie", "zapisnicavz", "vyhlasenie_konatela", "suhlas_vlastnika",
      "suhlas-spracovanie-udajov", "zmluva_o_prevode_op", "vyhlasenie_o_doruceni_zmluvy", "vyhlasenie_spolocnika",
      "suhlas-vlastnika-nehnutelnosti", "zakladatelska-listina", "spolocenska-zmluva", "vyhlasenie-spravcu-vkladu",
      "vyhlasenie-zakladatela-spolocnosti", "ziadost_zalozenie_zivnosti", "ziadost_rozsirenie_zivnosti",
      "cestne_prehlasenie_declaration", "prihlaska_na_zdravotne_poistenie", "ziadost_zrusenie_zivnosti",
      "ziadost_zmeny_zivnosti", "stanovy", "navrh"];

    let fileNamesWithPerson = ["podpisovy_vzor", "suhlas-zodp-zastupcu", "cestne_vyhlasenie_spolocnika_fo",
      "cestne_vyhlasenie_spolocnika_po", "ziadost_prevodca_fo", "ziadost_prevodca_po", "ziadost_nadobudatel_fo",
      "ziadost_nadobudatel_po", "podpisovy-vzor-konatel", "ziadost-du"];

    let indexFile, indexFilePerson = -1;

    fileNames.forEach((s, index) => {
      if(fileName.indexOf(s) > -1)
        indexFile = index;
    });
    fileNamesWithPerson.forEach((s, index) => {
      if(fileName.indexOf(s) > -1)
        indexFilePerson = index;
    });

    if(indexFilePerson > -1) {
      let person = fileName.substring(0, fileName.lastIndexOf("_")).substring(fileName.indexOf(fileNamesWithPerson[indexFilePerson])).replace(fileNamesWithPerson[indexFilePerson], "").replace(/_/g, " ");
      return $filter("translate")("documents." + fileNamesWithPerson[indexFilePerson]) + " -" + person;
    } else if(indexFile > -1)
      return $filter("translate")("documents." + fileNames[indexFile]);
    else return fileName;
  }

  function calculateDateOfBirth(idNumber) {
    let year = parseInt(idNumber.prefix.slice(0, 2));
    let month = parseInt(idNumber.prefix.slice(2, 4));
    let day = parseInt(idNumber.prefix.slice(4, 6));

    if(idNumber.input.length === 3) {
      year += 1900;
    } else if(year > 53) {
      year += 1900;
    } else {
      year += 2000;
    }

    if(month > 13) {
      month -= 50;
    }

    return {
      day: day,
      month: month,
      year: year
    }
  }

  function calculateIdNumber(d, gender) {
    date = d;

    return calculateYearPart() +
    calculateMonthPart(gender) +
    calculateDayPart();
  }

  function roundDate(number) {
    return number > 9 ? number : "0" + number;
  }

  function calculateYearPart() {
    //console.log(date);
    return date && date.year && date.year > 1000 ? date.year.toString().substring(2,4) : "";
  }

  function calculateMonthPart(gender) {
    if (date && date.month && gender) {
      let month = parseInt(date.month);
      let result = gender === "Žena" ? month + 50 : month;
      result = roundDate(result);
      return result;
    } else {
      return "";
    }
  }

  function calculateDayPart() {
    return date && date.day ? roundDate(parseInt(date.day)) : "";
  }

  function getUtmSource(orderType) {
    let utmSource = "";
    switch (orderType) {
      case 0:
        utmSource = "Firmaren-ZalozenieFirmy";
        break;
      case 1:
        utmSource = "Firmaren-ZmenaFirmy";
        break;
      case 2:
        utmSource = "Firmaren-ZalozenieZiv";
        break;
      case 3:
        utmSource = "Firmaren-ZrusenieZiv";
        break;
      case 4:
        utmSource = "Firmaren-Inzerat";
        break;
      case 5:
        utmSource = "Firmaren-ZalozenieOZ";
        break;
      case 6:
        utmSource = "Firmaren-ZmenaZiv";
        break;
      case 7:
        utmSource = "Firmaren-RozsirenieZiv";
        break;
      case 8:
        utmSource = "Firmaren-ReadyMade";
        break;
      case 10:
        utmSource = "Firmaren-ZalozenieOZSam";
        break;
      case 11:
        utmSource = "Firmaren-ZalozenieZivCudzinec";
        break;
      case 12:
        utmSource = "Firmaren-KonverziaE";
        break;
      default:
        utmSource = "Firmaren";
        break;
    }
    return utmSource;
  }

  function mergeStreetNo(address) {
    if(address && address.streetNoPrefix && address.streetNoPrefix !== "")
        return address.streetNoPrefix + "/" + address.streetNo;
    else {
        if(address && address.streetNo)
            return address.streetNo;
    }
  }

  function onElementFocus(id) {
    let element = $window.document.getElementById(id);
    if(element)
      element.focus();
  }

  function onElementBlur(id) {
    let element = $window.document.getElementById(id);
    if(element)
      element.blur();
  }

  function scrollTo(scrollLocation) {
    $('html, body').animate({
      scrollTop: $("#" + scrollLocation).offset().top - 170
    }, 500);
  }

  function scrollInModalTo(scrollLocation) {
    $('.modal').animate({
      scrollTop: $("#" + scrollLocation).offset().top - 170
    }, 500);
  }

  function fullName(item) {
    return (item.titleBefore ? item.titleBefore + " " : "")
      .concat(item.name ? item.name + " " : "")
      .concat(item.surname ? item.surname : "")
      .concat(item.titleAfter ? ", " + item.titleAfter : "");
  }

  function getFullName(person) {
    var res = "";

    if (person.titleBefore) {
      if (person.titleBefore.slice(-1) === ".")
        res += person.titleBefore + " ";
      else
        res += person.titleBefore + ". ";
    }

    res += person.name + " " + person.surname;

    if (person.titleAfter) {
      if (person.titleAfter.slice(-1) === ".")
        res += ", " + person.titleAfter;
      else
        res += ", " + person.titleAfter + ".";
    }

    return res;
  }

  function dateToString(date) {
    if(date && date.day && date.month && date.year) {
      return date.day + "." + date.month + "." + date.year;
    } else {
      return "";
    }
  }

  function idNumberToString(idNumber) {
    if(idNumber && idNumber.prefix && idNumber.input) {
      return idNumber.prefix + "/" + idNumber.input;
    } else {
      return "";
    }
  }

  function phoneNumberToString(phoneNumber) {
    if(phoneNumber && phoneNumber.prefix && phoneNumber.number) {
      return phoneNumber.prefix + " " + phoneNumber.number;
    } else {
      return "";
    }
  }

  function formatDate(item) {
    if(item) {
      let date = new Date(item);
      return date.getDate() + ". " + (date.getMonth() + 1) + ". " + date.getFullYear();
    }
  }

  function formatStreet(address) {
    if (angular.isDefined(address)) {
      if(address.streetNoPrefix && address.streetNoPrefix !== "")
        return `${address.street} ${address.streetNoPrefix}/${address.streetNo}`;
      else return `${address.street} ${address.streetNo}`;
    }
  }

  function formatCityZipCode(address) {
    if (angular.isDefined(address)) {
      return `${address.city} ${address.zipCode}`;
    }
  }

  function formatAddress(address) {
    if (angular.isDefined(address)) {
      return `${formatStreet(address)}, ${formatCityZipCode(address)}`;
    }
  }

  function formatParentName(name) {
    return `${name.name} ${name.surname}`;
  }

  function getBurzaUtmCampaign(id, name) {
    return removeDiacritics(id + "-" + name.replace(new RegExp(" ", "gi"), "-")).substring(0, 255);
  }

  function getDataLayer(orderId, order_type, order_category, order_service, user) {
    let prices = {
      'zalozenie_zivnost': [15.83, 0],
      'rozsirenie_zivnost': [16.18, 0],
      'zrusenie_zivnost': [16.18, 0],
      'pozastavenie_zivnost': [16.18, 0],
      'obnovenie_zivnost': [16.18, 0],
      'zmeny_zivnost': [16.18, 0],
      'zalozenie_zivnost_zvyhodnena': [8.33, 0],
      'rozsirenie_zivnost_zvyhodnena': [8.33, 0],
      'zrusenie_zivnost_zvyhodnena': [8.33, 0],
      'pozastavenie_zivnost_zvyhodnena': [8.33, 0],
      'obnovenie_zivnost_zvyhodnena': [8.33, 0],
      'zmeny_zivnost_zvyhodnena': [8.33, 0],
      'zalozenie_sro': [90.83, 0],
      '1osobova_sro': [90.83, 0],
      'kuv': [30, 0],
      'predaj_firmy': [16.18, 0],
      'zmeny_sro': [73.17, 0],
      'zmeny_sro_prevod_imanie': [138.21, 0],
      'ready_made': [445, 0],
      'zalozenie_oz': [69.1, 0],
      'ready_made_oz': [464, 0],
      'ready_made_oz_2%': [464, 0],
      'uss_oz': [16.18, 0],
      'uss_zmena_udajov_or': [16.18, 0],
      'zalozenie_zivnost_cudzinec': [56.1, 0],
      'zrusenie_zivnost_cudzinec': [36.59, 0],
      'pozastavenie_zivnost_cudzinec': [36.59, 0],
      'zmeny_zivnost_cudzinec': [36.59, 0],
      'ochranna_znamka': [0, 0],
      'likvidacia_sro': [0, 0],
      'likvidacia_oz': [0, 0],
      'zmeny_oz': [0, 0],
      'vypis_z_registrov': [9, 0],
      'uctovnici': [300, 0]
    };

    let price = prices[order_type][0];

    if(order_type === "zmeny_sro_prevod_imanie") {
      order_type = "zmeny_sro"
      order_service = "prevod_imanie"
    }

    if(order_type.indexOf("zvyhodnena") !== -1) {
      order_type = order_type.replace("_zvyhodnena", "");
      order_service = "zvyhodnena_cena"
    }

    console.log(prices);
    console.log(prices["zalozenie_sro"][0]);
    console.log(prices["zalozenie_sro"][1]);

    return {
      'event': 'order',
      'order_id': orderId,
      'order_value': price,
      // 'order_cost': prices[order_type][1],
      'order_type': order_type,
      'order_service': order_service,
      'order_category': order_category,
      'order_currency': 'EUR',
      'user_id': user ? user.id: -1,
      'email': user ? user.email : '',
      'phone': user ? user.phoneNumber : '',
      'first_name': user ? user.firstName : '',
      'last_name': user ? user.lastName : '',
      'address': user && user.address ? user.address.street + ' ' + user.address.streetNo : '',
      'city': user && user.address ? user.address.city : '',
      'country': 'SK',
      'postal_code': user && user.address ? user.address.zipCode : ''
    };
  }


}

angular.module("firmaren")
  .service("Helpers", Helpers);
}());
