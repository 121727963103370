;(function() {
/* @ngInject */
function LandingController(IndexService, _, $window, ModalOrder, $location, $scope, routes, ModalVideo,
                           $translate, Helpers, ModalNameDay, $filter) {

  let vm = this;
  let server = IndexService;

  vm.showAll = showAll;
  vm.order = order;
  vm.statsLoad = statsLoad;
  vm.scrollTo = Helpers.scrollTo;
  vm.readyMade = readyMade;
  vm.statsForChangeTradeLoad = statsForChangeTradeLoad;

  vm.onSaveAndContinueClick = onSaveAndContinueClick;
  vm.setEndUserCollapsed = setEndUserCollapsed;
  vm.playVideo = playVideo;
  vm.resetCompanyData = resetCompanyData;
  vm.setTypeOfChange = setTypeOfChange;
  vm.setSinglePerson = setSinglePerson;
  vm.showNameDayModal = showNameDayModal;
  vm.getPrice = getPrice;
  vm.getPriceForServices = getPriceForServices;
  vm.findByName = findByName;

  // TODO FIRM-1581 - zrusenie zalozenia zivnosti za 1 euro
  // vm.showDiscountModal = showDiscountModal;

  vm.showButton = true;
  vm.showTooltip = false;
  vm.statLoading = false;
  vm.googleReviews = [];

  vm.lang = $translate.use();

  vm.changeCompany = {
    isCollapsed: {
      executors: true,
      companyName: true,
      place: true,
      subjects: true,
      founders: true,
      capital: true,
      other: true,
      firmarenAddress: true,
      clerks: true,
      endUser: true,
      executionType: true,
      companyTransfer: true
    }
  };

  server.getSpolocnosti().then(onSpolocnostiLoad);
  server.getGoogleReviews().then(data => vm.googleReviews = data);

  function getPrice(name) {
    server.getPriceForName(name).then(price => vm.price = price)
  }

  function getPriceForServices() {
    server.getPriceItems().then(onItemsLoad);
  }

  function onItemsLoad(items) {
    vm.prices = items;
  }

  function findByName(name) {
    if(vm.prices)
      return _.find(vm.prices, function(obj) { return obj.item === name}).price;
  }

  function onSaveAndContinueClick() {
    if ($scope.zmena.$invalid) {
      vm.requiredMessage = true;
      vm.submitted = true;
    } else {
      server.setChangeCompanyData({ico: vm.ico, isCollapsed: vm.changeCompany.isCollapsed})
          .then(() => {
              $window.location.href = (routes.sro.zmena.krok1);
          })
          .catch((e) => console.log(`todo: ${e}`));
    }
  }

  function setTypeOfChange(change) {
    server.setTypeOfChangeTrade(change)
        .then(() => {
          $window.location.href = (routes.zivnosti.zmeny.krok1);
        })
        .catch((e) => {
          console.log(`todo: ${e}`);
          $window.location.href = (routes.zivnosti.zmeny.krok1);
        });
  }

  function resetCompanyData() {
    server.resetCompanyData()
        .then(() => {
          $window.location.href = (routes.sro.zmena.krok1);
        })
        .catch((e) => {
          console.log(`todo: ${e}`);
          $window.location.href = (routes.sro.zmena.krok1);
        });
  }

  function setEndUserCollapsed() {
    server.setChangeCompanyData({ico: "", isCollapsed: vm.changeCompany.isCollapsed})
        .then(() => {
          $window.location.href = (routes.sro.zmena.krok1);
        })
        .catch((e) => {
          console.log(`todo: ${e}`);
          $window.location.href = (routes.sro.zmena.krok1);
        });
  }

  function setSinglePerson(isSingle) {
    server.setSinglePerson(isSingle.toString()).then(() => {
          $window.location.href = (routes.sro.zalozenie.krok1);
        }).catch((e) => {
          console.log(`todo: ${e}`);
          $window.location.href = (routes.sro.zalozenie.krok1);
        });
  }

  function playVideo(url) {
      ModalVideo(url)
          .add()
          .result
          .then();
  }

  function onSpolocnostiLoad(items) {
    vm.allItems = items;
    vm.items = items.slice(0, 4);
  }

  function showAll() {
    vm.items = vm.allItems;
    vm.showButton = false;
    vm.showTooltip = true;
  }

  function statsForChangeTradeLoad(changeType) {
    if (!vm.stat && !vm.statLoading) {
      vm.statLoading = true;
      server.getStatsForChangeTrade(changeType).then(data => {
        vm.statLoading = false;
        if (data) {
          vm.stat = data;
        }
      }, err => {
        vm.statLoading = false;
      });
    }
    return vm.stat;
  }

  function statsLoad(name) {
    if (!vm.stat && !vm.statLoading) {
      vm.statLoading = true;
      server.getStats({name: name}).then(data => {
        vm.statLoading = false;
        if (data) {
          vm.stat = data.count;
        }
      }, err => {
        vm.statLoading = false;
      });
    }
    return vm.stat;
  }

  function readyMade(company) {
    $window.location.href = routes.sro.readymade.krok1 + "?ico=" + company.ico;
  }

  function order(item, form, trackForm) {
    ModalOrder(server.setContact, item, form, trackForm)
      .add(server.setContact, item, form, trackForm)
      .result
      .then();
  }

  // TODO FIRM-1581 - zrusenie zalozenia zivnosti za 1 euro
  // function showDiscountModal() {
  //   if(vm.lang !== "en") {
  //     ModalUpsell(false, "upsells.modal.buttons.lp.submit", "upsells.modal.buttons.lp.cancel")
  //         .add()
  //         .result
  //         .then(res => {
  //           if (res && res === "confirm") {
  //             $window.location.href = routes.zivnosti.zalozenie.krok1
  //           }
  //         });
  //   }
  // }

  function showNameDayModal(type) {
    if(vm.lang !== "en") {
      server.getNameDay(type)
          .then((name) => {
            if (name && name.length > 0) {
              let prefix = (type && type === 'trade') ? "zalozenieZivnosti" : "sro";
              ModalNameDay($filter("translate")(prefix + '.nameDay.title',
                  {name: name}), prefix + '.nameDay.subtitle', prefix + '.nameDay.desc')
                  .add()
                  .result
                  .then();
            }
          });
    }
  }


}

angular.module("firmaren")
  .controller("LandingController", LandingController);
}());
